import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_ctx.isCalendarField)
    ? (_openBlock(), _createBlock(_component_Calendar, {
        key: 0,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        showIcon: true,
        dateFormat: "mm-dd-yy",
        class: _normalizeClass(_ctx.inputClass),
        disabled: _ctx.isFieldReadOnly,
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "class", "disabled", "onChange"]))
    : (_ctx.isMultiSelectField)
      ? (_openBlock(), _createBlock(_component_MultiSelect, {
          key: 1,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          options: _ctx.selectionOptions,
          optionLabel: _ctx.optionLabel,
          optionValue: _ctx.optionValue,
          placeholder: `Select ${_ctx.field.display_name}`,
          class: _normalizeClass(_ctx.inputClass),
          disabled: _ctx.isFieldReadOnly,
          onChange: _ctx.handleChange
        }, null, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "class", "disabled", "onChange"]))
      : (_ctx.isDropdownField)
        ? (_openBlock(), _createBlock(_component_DropDown, {
            key: 2,
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
            options: _ctx.selectionOptions,
            optionLabel: _ctx.optionLabel,
            optionValue: _ctx.optionValue,
            placeholder: `Select ${_ctx.field.display_name}`,
            filter: "",
            class: _normalizeClass(_ctx.inputClass),
            disabled: _ctx.isFieldReadOnly,
            onChange: _ctx.handleChange
          }, null, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "class", "disabled", "onChange"]))
        : (_ctx.isNumberField)
          ? (_openBlock(), _createBlock(_component_InputNumber, {
              key: 3,
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
              class: _normalizeClass(_ctx.inputClass),
              disabled: _ctx.isFieldReadOnly,
              "min-fraction-digits": _ctx.factionDigits,
              "max-fraction-digits": _ctx.factionDigits,
              onBlur: _ctx.handleChange
            }, null, 8, ["modelValue", "class", "disabled", "min-fraction-digits", "max-fraction-digits", "onBlur"]))
          : (_ctx.isBooleanField)
            ? (_openBlock(), _createBlock(_component_InputSwitch, {
                key: 4,
                "true-value": _ctx.booleanTrueValue,
                "false-value": _ctx.booleanFalseValue,
                class: _normalizeClass(_ctx.inputClass),
                disabled: _ctx.isFieldReadOnly,
                onChange: _ctx.handleChange,
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event))
              }, null, 8, ["true-value", "false-value", "class", "disabled", "onChange", "modelValue"]))
            : (_openBlock(), _createBlock(_component_InputText, {
                key: 5,
                type: "text",
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value) = $event)),
                class: _normalizeClass(_ctx.inputClass),
                disabled: _ctx.isFieldReadOnly,
                onBlur: _ctx.handleChange
              }, null, 8, ["modelValue", "class", "disabled", "onBlur"]))
}