
import { defineComponent } from "vue";

import Calendar from "primevue/calendar";
import DropDown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import { Field } from "../../types/fdict";

import Utils from "../../utility/utils";

export default defineComponent({
  name: "RoverInput",
  components: {
    Calendar,
    DropDown,
    InputNumber,
    InputText,
    MultiSelect,
    InputSwitch,
  },
  emits: ["update:modelValue", "validate-input"],
  props: {
    field: {
      required: true,
      type: Object as () => Field,
    },
    invalidInput: {
      default: false,
      type: Boolean,
    },
    modelValue: null,
    options: {
      default: () => [],
      type: Array,
    },
    optionLabel: {
      default: "valid_desc",
      type: String,
    },
    optionValue: {
      default: "valid_value",
      type: String,
    },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  computed: {
    inputClass(): string {
      const classes = [];
      if (this.isBooleanField) {
        classes.push("align-self-center");
      }

      if (this.invalidInput) {
        classes.push("p-invalid");
      }

      return classes.join(" ");
    },
    isBooleanField(): boolean {
      return Utils.booleanCheck(this.field.boolean);
    },
    isCalendarField(): boolean {
      if (this.field.conv && this.field.conv.length > 0) {
        return this.field.conv[0] === "D";
      }
      return false;
    },
    factionDigits(): number | undefined {
      if (this.field.conv && this.field.conv.length > 2) {
        return parseInt(this.field.conv[2]);
      }
      return undefined;
    },
    isMultiSelectField(): boolean {
      if (this.selectionOptions.length > 0) {
        return Utils.booleanCheck(this.field.multi_line);
      }
      return false;
    },
    isDropdownField(): boolean {
      if (this.selectionOptions.length > 0) {
        return true;
      }
      return false;
    },
    isNumberField(): boolean {
      if (this.field.conv && this.field.conv.length > 1) {
        return this.field.conv[0] === "M" && this.field.conv[1] === "D";
      } else {
        return false;
      }
    },
    isFieldReadOnly(): boolean {
      return Utils.booleanCheck(this.field.read_only);
    },
    isWebValidate(): boolean {
      return Utils.booleanCheck(this.field.web_validate);
    },
    booleanTrueValue(): string {
      if (
        this.field.valid_value_items &&
        this.field.valid_value_items.length > 0
      ) {
        return this.field.valid_value_items[0].valid_value;
      } else {
        return "Y";
      }
    },
    booleanFalseValue(): string {
      if (
        this.field.valid_value_items &&
        this.field.valid_value_items.length > 1
      ) {
        return this.field.valid_value_items[1].valid_value;
      } else {
        return "N";
      }
    },
    selectionOptions(): any[] {
      if (this.options && this.options.length > 0) {
        return this.options;
      } else if (
        this.field.valid_value_items &&
        this.field.valid_value_items.length > 0
      ) {
        return this.field.valid_value_items;
      }
      return [];
    },
  },
  methods: {
    handleChange(event: any) {
      if (this.isWebValidate) {
        this.$emit("validate-input", this.field);
      }
    },
  },
  watch: {
    modelValue(newValue: any) {
      this.value = newValue;
    },
    value(newValue: any) {
      let value = newValue;
      if (this.isCalendarField) {
        value = Utils.formatDate(newValue);
      } else if (this.isNumberField) {
        value = newValue.toString();
      }

      this.$emit("update:modelValue", value);
    },
  },
});
