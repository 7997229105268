import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid col-12 p-0 m-0 justify-content-center" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "grid p-0 m-0 mt-2 justify-content-center pl-1 pr-2" }
const _hoisted_4 = {
  key: 0,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-6"
}
const _hoisted_5 = { class: "p-inputgroup" }
const _hoisted_6 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShippingInfo = _resolveComponent("ShippingInfo")!
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_OrderHeaderAdditionalInformationPane = _resolveComponent("OrderHeaderAdditionalInformationPane")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.posOrderHeaderShipToDisplay === _ctx.orderHeaderShipToDisplayOptions.Top)
      ? (_openBlock(), _createBlock(_component_ShippingInfo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.getCompanyCodes.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.companyCodeLabel), 1),
                  _createVNode(_component_DropDown, {
                    modelValue: _ctx.getSalesOrder.co_code,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getSalesOrder.co_code) = $event)),
                    options: _ctx.getCompanyCodes,
                    optionLabel: "name",
                    optionValue: "code",
                    placeholder: _ctx.companyCodeLabel,
                    filter: "",
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:companyCode', $event)))
                  }, null, 8, ["modelValue", "options", "placeholder"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_OrderHeaderAdditionalInformationPane, {
        "onUpdate:isOrderHeaderValid": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:isOrderHeaderValid', $event)))
      })
    ]),
    (
      _ctx.posOrderHeaderShipToDisplay === _ctx.orderHeaderShipToDisplayOptions.Bottom
    )
      ? (_openBlock(), _createBlock(_component_ShippingInfo, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}