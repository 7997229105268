<template>
  <div class="grid">
    <div
      class="col-12 mt-0 mr-0 ml-0 flex flex-column md:flex-row justify-content-between"
    >
      <div v-if="!posHideInvoicesArKpi" class="col-12 p-0 m-0">
        <Receivables
          :items="[
            { label: 'Current', value: agingSummary.current, color: '#58b41c' },
            {
              label: '31-60',
              value: agingSummary.over_thirty,
              color: '#04789f',
            },
            {
              label: '61-90',
              value: agingSummary.over_sixty,
              color: '#fa332e',
            },
            { label: '90+', value: agingSummary.over_ninety, color: '#ca0c04' },
          ]"
          :total="{
            label: 'Balance',
            value: agingSummary.balance,
            color: '#8A2BE2',
          }"
          :showBars="true"
          :showTotal="true"
          :roundTotals="false"
          :selectedIdx="selectedIdx"
          :emailLoading="emailLoading"
          :loadingStatement="loadingStatement"
          @row:click="handleRowClick"
          @download:statement="getPDFStatement"
          @email:statement="handleShowEmailPDFDialog"
        />
      </div>
    </div>
    <div class="col-12 p-0 px-2 pt-1 m-0">
      <div class="mt-0">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <div>
            <Search
              ref="search_invoice"
              label="name"
              file="AR"
              currentView="Pos"
              placeholder="Search for Invoices"
              :leftIcon="true"
              :scannerMode="true"
              class="large-search-input my-1"
              :searchFunction="lookUpInvoice"
            />
          </div>
          <div>
            <Button
              class="ml-1 my-1"
              label="Load All Invoices"
              icon="pi pi-search"
              @click="handleLoadAllInvoices"
            />
          </div>
        </div>
        <Card>
          <template #content>
            <ReceivablesTable
              :range="[dateStart, dateEnd]"
              :showType="false"
              :showToPay="false"
              :selectedItems="selectedItems"
              :forceFetch="loadInvoices"
              :loadOnCreate="!manuallyLoadInvoices"
              :hidePoNo="hidePoNo"
              :noDefaultSort="true"
              :hideFilters="true"
              @update:forceFetch="loadInvoices = $event"
              @rowClick="rowClick"
              :compact="isCompact"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
  <EmailFileDialog
    :show="showEmailPDFDialog"
    :header="'Email Statement ' + getCustomer.name"
    :fileId="getCustomer.cust_id"
    :fileName="'statement.pdf'"
    :contacts="getCustomer.contact_id_items"
    @onEmail="sendEmail"
    @hide="showEmailPDFDialog = false"
    @onAddressAdded="handleAddressAdded"
  />
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";

import Tooltip from "primevue/tooltip";
import Card from "primevue/card";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import Receivables from "@/components/UI/Receivables.vue";

import InvoiceService from "@/services/InvoiceService";
import ARService from "@/services/ARService";
import Utils from "@/utility/utils";
import Search from "@/components/Search.vue";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import CustomerService from "@/services/CustomerService";

import EventBus, { AppEvents } from "@/utility/EventBus";
import ReceivablesTable from "../Accounting/Receivables/ReceivablesTable.vue";
import { BREAKPOINT } from "@/utility/global";
import { cloneDeep } from "lodash";

const custService = new CustomerService();
const invoiceService = new InvoiceService(process.env.VUE_APP_ABSTRACTION_API);
const arService = new ARService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Invoices",
  components: {
    ReceivablesTable,
    Card,
    Button,
    EmailFileDialog,
    Search,
    Receivables,
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted() {
    window.addEventListener("resize", this.setIsCompact);
    this.setIsCompact();
    this.agingLoading = false;
    arService
      .getAging(this.getCustomer.cust_id)
      .then((response) => {
        if (response.aging) {
          const agingList = response.aging.find(
            (aging) => aging.cust_id === this.getCustomer.cust_id,
          );
          if (agingList) {
            this.agingSummary = agingList;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.agingLoading = false;
      });
    this.$refs.search_invoice.$refs.autocomplete.$el.children[0].focus();

    this.getMrkControl({
      client: store.getters["session/getClient"],
      selectedCode: this.getSelectedCoCode,
      refresh: false,
    });
    EventBus.on(AppEvents.PosReloadInvoices, () => {
      this.fetchInvoices(false);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setIsCompact);
    EventBus.off(AppEvents.PosReloadInvoices);
  },
  computed: {
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "pos/getCustomer",
      getClient: "session/getClient",
      disable_downloading_statements: "mrkControl/disableDownloadingStatements",
      disable_emailing_statements: "mrkControl/disableEmailingStatements",
      manuallyLoadInvoices: "mrkControl/manuallyLoadInvoices",
      hidePoNo: "mrkControl/hideInvoicesPoNo",
      posHideInvoicesArKpi: "mrkControl/posHideInvoicesArKpi",
    }),
    selectedItems() {
      return this.getOrder.map((item) => item.ar_id) || [];
    },
  },
  data() {
    return {
      emptyTableLabel: "Invoices have not been loaded",
      loading: false,
      selectedStatus: "",
      showEmailPDFDialog: false,
      selectedDateRange: "",
      sortOrder: 1,
      sortField: "",
      invoices: [],
      status: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      invoicesView: [],
      loadingPDFInvoices: [],
      loadedPDFs: [],
      agingSummary: {},
      agingLoading: false,
      loadingStatement: false,
      emailLoading: false,
      dateStart: "",
      dateEnd: "",
      selectedIdx: -1,
      loadInvoices: false,
      isCompact: false,
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      addPartToOrder: "pos/addPartToOrder",
      setCust: "pos/setCustomer",
      setReg: "pos/setRegister",
      setCustomerContacItems: "pos/setCustomerContacItems",
      setLastItemChanged: "pos/setLastItemChanged",
      getMrkControl: "mrkControl/getMrkControl",
      removeFromOrder: "pos/removeFromOrder",
    }),
    handleRowClick(data) {
      switch (data.label) {
        case "Current":
          this.setDatesToFetch(30, -1);
          break;
        case "31-60":
          this.setDatesToFetch(60, 31);
          break;
        case "61-90":
          this.setDatesToFetch(90, 61);
          break;
        case "90+":
          this.setDatesToFetch(45000, 91);
          break;

        default:
          this.dateStart = "";
          this.dateEnd = "";
          break;
      }

      this.fetchInvoices(false);
    },
    setDatesToFetch(start, end) {
      let dateStart = new Date();
      let dateEnd = new Date();
      dateStart.setDate(dateStart.getDate() - start);
      dateEnd.setDate(dateEnd.getDate() - (end < 0 ? -365 : end));
      this.dateStart = Utils.roverDateString(dateStart, true);
      this.dateEnd = Utils.roverDateString(dateEnd, true);
    },
    handleLoadAllInvoices() {
      this.dateStart = "";
      this.dateEnd = "";
      this.loadInvoices = true;
    },
    rowClick(event) {
      let inv = cloneDeep(event.data);
      inv.type = "IN";
      const index = this.getOrder.findIndex(
        (item) => item.ar_id === event.data.ar_id,
      );
      if (index === -1) {
        this.addPartToOrder(inv);
      } else {
        this.removeFromOrder(index);
      }
    },
    handleShowEmailPDFDialog() {
      this.showEmailPDFDialog = true;
    },
    sendEmail(data) {
      this.emailLoading = true;
      const cuttoff_date = Utils.roverDateString(new Date());
      arService
        .getStatement(this.getCustomer.cust_id, cuttoff_date, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Statement has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Statement was not sent`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.addNotification({
            message: `Statement could not be sent: ${err}`,
            type: "error",
          });
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },
    getPDFStatement() {
      this.loadingStatement = true;
      const cuttoff_date = Utils.roverDateString(new Date());
      arService
        .getStatement(this.getCustomer.cust_id, cuttoff_date)
        .then((response) => {
          const bufferArray = Utils.base64ToArrayBuffer(response);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
        })
        .catch((err) => {
          this.addNotification({
            message: `Failed to download statement. Please try again`,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingStatement = false;
        });
    },
    updatePDFLoadingState(invoiceId) {
      const index = this.loadingPDFInvoices.indexOf(invoiceId, 0);
      if (index > -1) this.loadingPDFInvoices.splice(index, 1);
    },
    fetchInvoices() {
      this.forceFetch = true;
    },
    lookUpInvoice(input, searchComponent) {
      if (input) {
        invoiceService
          .setInvoices(
            "1",
            "1",
            this.getCustomer.cust_id,
            this.getClient,
            "",
            "",
            "",
            "",
            input,
            "",
            "so_total so_status",
          )
          .then((response) => {
            if (response.ar_items?.length > 0) {
              if (
                response.ar_items[0].cust !==
                (this.getCustomer.custid || this.getCustomer.cust_id)
              ) {
                this.addNotification({
                  message: `Invoice #${input} is not associated with this customer.`,
                  type: "error",
                });
              } else {
                this.rowClick({ data: response.ar_items[0] });
              }
            } else {
              this.addNotification({
                message: `Invoice #${input} was not found.`,
                type: "error",
              });
            }
          })
          .finally(() => {
            searchComponent.$refs.autocomplete.searching = false;
            searchComponent.$refs.autocomplete.$el.children[0].blur();
            searchComponent.$refs.autocomplete.$el.children[0].focus();
          });
      }
    },
    handleAddressAdded() {
      custService
        .getCustomer(
          this.getCustomer.cust_id,
          this.getClient,
          "contact_email contact_name",
        )
        .then((response) => {
          this.setCustomerContacItems(response.contact_id_items);
        });
    },
    setIsCompact() {
      this.isCompact = window.innerWidth < BREAKPOINT;
    },
  },
});
</script>

<style scoped>
.aging-item {
  border-bottom: 1px solid #f3f3f3;

  .item-label {
    color: black !important;
  }
}

.red-text {
  color: red !important;
}
</style>
