import ARService from "@/services/ARService";
import GetAccountsReceivableRequest from "@/types/services/accountsReceivable";
import store from "@/store";
import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

export function useFetchArRecords(service: ARService) {
  async function fetchArRecords(
    request: Partial<GetAccountsReceivableRequest>,
  ) {
    try {
      const arRecs = await service.getARRecords(request);
      for (let i = 0; i < arRecs.ar_items.length; i++) {
        // for proper datatable sort by date, date strings are converted to date objects
        arRecs.ar_items[i].invoice_date = 
        arRecs.ar_items[i].invoice_date != null && arRecs.ar_items[i].invoice_date != "" && arRecs.ar_items[i].invoice_date != undefined
            ? (new Date(arRecs.ar_items[i].invoice_date) as unknown as string) 
            : "";
        arRecs.ar_items[i].due_date = 
        arRecs.ar_items[i].due_date != null && arRecs.ar_items[i].due_date != "" && arRecs.ar_items[i].due_date != undefined
            ? (new Date(arRecs.ar_items[i].due_date) as unknown as string) 
            : "";
      }
      return arRecs;
    } catch (error: any) {
      store.dispatch(ADD_ERROR_NOTIFICATION, {
        message: `Error Displaying Accounts Receivable Records. ${
          error.error || error.message
        }.`,
      });
    }
  }

  return { fetchArRecords };
}
