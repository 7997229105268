
import ShippingInfo from "./ShippingInfo.vue";
import OrderHeaderAdditionalInformationPane from "./OrderHeaderAdditionalInformationPane.vue";
import DropDown from "primevue/dropdown";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { FDICT_SO } from "@/utility/fdicts/so";
import { OrderHeaderShipToDisplayOptions } from "@/types/controls/mrk";

export default defineComponent({
  components: {
    DropDown,
    OrderHeaderAdditionalInformationPane,
    ShippingInfo,
  },
  data() {
    return {
      orderHeaderShipToDisplayOptions: OrderHeaderShipToDisplayOptions,
    };
  },
  emits: ["update:isOrderHeaderValid", "update:companyCode"],
  computed: {
    ...mapGetters({
      getCompanyCodes: "coControl/getCodeItems",
      getSalesOrder: "pos/getSalesOrder",
      getFieldLabel: "fdict/getFieldLabel",
      posOrderHeaderShipToDisplay: "mrkControl/posOrderHeaderShipToDisplay",
    }),
    companyCodeLabel(): string {
      // TODO - Create object map for company code to field number
      return this.getFieldLabel("SO", FDICT_SO.CO_CODE, "Company Code");
    },
  },
});
