
import { defineComponent, computed } from "vue";
import store from "@/store";
import Steps from "primevue/steps";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import ConfirmDialog from "primevue/confirmdialog";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import { mapActions, mapGetters, mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { minValue, maxValue, required, helpers } from "@vuelidate/validators";
import BatchService from "@/services/BatchService";
import PoService from "@/services/PoService";
import Invoice from "@/types/invoice";
import PosCartItems from "@/types/PosCartItems";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MessageBox from "@/components/MessageBox.vue";
import OrderSummaryItemWrapper from "./Pos/OrderSummaryItemWrapper.vue";
import RegisterHardwareDetail from "@/types/registerHardwareDetail";
import RegisterFetch from "@/types/registerfetch";
import ShipToAddressesTable from "./Pos/ShipToAddressesTable.vue";
import AddShippingAddressDialog from "@/components/Pos/AddShippingAddressDialog.vue";
import AuthenticateDialog from "@/components/Pos/AuthenticateDialog.vue";
import UnsavedOrdersDialog from "@/components/Pos/UnsavedOrdersDialog.vue";
import PosSOCartDialog from "@/components/Pos/PosSOCartDialog.vue";
import PosInvoiceCartDialog from "@/components/Pos/PosInvoiceCartDialog.vue";

//Utilities
import EventBus, { AppEvents } from "@/utility/EventBus";
import Utils from "@/utility/utils";

// Services
import CustomerService from "@/services/CustomerService";
import PaymentService from "@/services/PaymentService";
import RegService from "@/services/RegService";
import LocalDesktopService from "@/services/LocalDesktopService";
import SalesService from "@/services/SalesService";
import ActionService from "@/services/ActionService";
import ShipService from "@/services/ShipService";

// Constants
import { FDICT_SO } from "@/utility/fdicts/so";
import { FDICT_PARTS } from "@/utility/fdicts/parts";

const customerService = new CustomerService();
const batchService = new BatchService(process.env.VUE_APP_ABSTRACTION_API);
const actionService = new ActionService(process.env.VUE_APP_ABSTRACTION_API);
const shipService = new ShipService(process.env.VUE_APP_ABSTRACTION_API);
const poService = new PoService();
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const regService = new RegService(process.env.VUE_APP_ABSTRACTION_API);
const salesService = new SalesService();
const localDesktopService = new LocalDesktopService();

//Types
import { Fdict, Field, isFieldReadOnly } from "@/types/fdict";
import { LisItems } from "@/types/salesorder";
import SalesOrder from "@/types/salesorder";
import { ShipItem } from "@/types/customer";
import { cloneDeep } from "lodash";
import { NotificationType } from "@/types/notification";

export default defineComponent({
  name: "PosForm",
  components: {
    TabView,
    TabPanel,
    Button,
    Divider,
    InputText,
    InputNumber,
    Dialog,
    ConfirmDialog,
    Checkbox,
    Steps,
    SplitButton,
    LoadingSpinner,
    MessageBox,
    OrderSummaryItemWrapper,
    ShipToAddressesTable,
    AddShippingAddressDialog,
    AuthenticateDialog,
    UnsavedOrdersDialog,
    PosSOCartDialog,
    PosInvoiceCartDialog,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      newInvAmt: {
        minValue: minValue(this.newInvAmtMin),
        maxValue: maxValue(this.newInvAmtMax),
        required,
      },
    };
  },
  computed: {
    ...mapState(["pos", "session"]),
    ...mapGetters({
      getCats: "control/getCategoryItems",
      getFilters: "control/getFilterItems",
      getTransTypes: "control/getTransactionTypes",
      getPayTypes: "control/getPaymentTypes",
      getOrder: "pos/getCurrentOrder",
      getRegister: "pos/getRegister",
      getCustomer: "pos/getCustomer",
      getUser: "session/getUser",
      getTenders: "pos/getTenders",
      getTaxAmount: "pos/getTaxAmount",
      getCompanyCodes: "coControl/getCodeItems",
      getDefaultCodeItem: "coControl/getDefaultCodeItem",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getRacks: "pos/getRacks",
      getClient: "session/getClient",
      isPriceCodeLocked: "priceControl/isPriceCodeLocked",
      showPosNotes: "mrkControl/showPosNotes",
      posOrderCodes: "mrkControl/posOrderCodes",
      salesOrderTypes: "mrkControl/salesOrderTypes",
      hidePartsTab: "mrkControl/hidePartsTab",
      controlFieldValues: "fdict/controlFieldValues",
      customFDictFields: "fdict/customFields",
      getStoredCartItems: "pos/getStoredCartItems",
      disableUpdatingPartDescription:
        "mrkControl/disableUpdatingPartDescription",
      promptOnPriceOverride: "mrkControl/promptOnPriceOverride",
      orderInformationPrecedesCreateOrder:
        "mrkControl/orderInformationPrecedesCreateOrder",
      posOrderSummaryItems: "mrkControl/posOrderSummaryItems",
      posOrderSubTotalItems: "mrkControl/posOrderSubTotalItems",
      posOrderCustomSubTotaling: "mrkControl/posOrderCustomSubTotaling",
      postInvoiceOnShip: "mrkControl/postInvoiceOnShip",
      getSalesOrder: "pos/getSalesOrder",
      getOldSalesOrder: "pos/getOldSalesOrder",
      getCurrentUnsavedOrder: "pos/getCurrentUnsavedOrder",
      hideDraftedOrders: "mrkControl/hideDraftedOrders",
      isSideBarCollapsed: "sidemenu/getCollapsed",
      getPOSRegisterPrinter: "pos/getRegisterPrinter",
      applyCmToOpenOrder: "mrkControl/applyCmToOpenOrder",
      getCurrentActivity: "pos/getCurrentActivity",
      posShowPayTerms: "mrkControl/posShowPayTerms",
      posShowTaxCodes: "mrkControl/posShowTaxCodes",
      posShowBookDate: "mrkControl/posShowBookDate",
      posShowShipVia: "mrkControl/posShowShipVia",
      posShowFreightAmount: "mrkControl/posShowFreightAmount",
      posShowFrtServCode: "mrkControl/posShowFrtServCode",
      posShowSalesRep: "mrkControl/posShowSalesRep",
      getTerms: "terms/getTerms",
      shipViaItems: "shipControl/getShipViaItems",
      posShowOrderStatus: "mrkControl/posShowOrderStatus",
      getFieldLabel: "fdict/getFieldLabel",
      getWebAccess: "session/getWebAccess",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
      getReps: "rep/getReps",
      posAllowPartialShipments: "mrkControl/posAllowPartialShipments",
      allowMixedMode: "mrkControl/allowMixedMode",
      getShowPickTicketPrint: "mrkControl/showPickTicketPrint",
      getPosAddOrderToCart: "mrkControl/posAddOrderToCart",
      getPosRequireEntryUserAuthentication:
        "mrkControl/posRequireEntryUserAuthentication",
      getEntryUser: "pos/getEntryUser",
      posShowOrderConfirmationPage: "mrkControl/posShowOrderConfirmationPage",
      posAllowPayout: "mrkControl/posAllowPayout",
      posExtraInfoItems: "mrkControl/posExtraInfoItems",
      getFdictFields: "fdict/getFdictFields",
      getField: "fdict/getField",
      posTermsRequirePayment: "mrkControl/posTermsRequirePayment",
      prohibitOpenPoShipments: "mrkControl/prohibitOpenPoShipments",
      posAutoCreateCust: "mrkControl/posAutoCreateCust",
      posAllowRefunds: "mrkControl/posAllowRefunds",
      getRegisterOptions: "pos/getRegisterOptions",
      posDisableStax: "mrkControl/posDisableStax",
      getAccess: "session/getAccess",
      hideUseNewAddressButton: "mrkControl/hideUseNewAddress",
      getPosAutoValidate: "mrkControl/posAutoValidate",
      correlativeExists: "fdict/correlativeExists",
      fieldExists: "fdict/fieldExists",
      getAutosaveDraftOrder: "pos/getAutosaveDraftOrder",
    }),

    showOrderSubTotal(): boolean {
      return (
        this.$route.fullPath === "/pos/parts" ||
        this.$route.fullPath === "/pos/shipping"
      );
    },

    showOrderAmount(): boolean {
      return this.correlativeExists("SO", "ORDER.AMOUNT");
    },
    dynamicClearInvoiceText(): string {
      return this.pos.currentOrder.length > 1
        ? "Clear Invoices"
        : "Clear Invoice";
    },
    dynamicInvoiceText(): string {
      return this.pos.currentOrder.length > 1
        ? "Are you sure you want to clear the invoices?"
        : "Are you sure you want to clear the invoice?";
    },
    clickable(): string {
      return this.$route.fullPath === "/pos/confirmation"
        ? "cursor-not-allowed"
        : "cursor-pointer focus:surface-200";
    },
    isRegisterClosed(): boolean {
      return this.pos.register?.status === "Closed";
    },
    continueToPaymentMessage(): string {
      return "Would you like to pay for Invoice #" + this.newInvoice + " now?";
    },
    hasOpenPo(): boolean {
      return this.getOrder.some((order: any) =>
        order.lis_items.some(
          (line: any) =>
            (line.po_id && line.po_status !== "C") ||
            (line.part_type === "L" && line.po_status !== "C"),
        ),
      );
    },
    isPaymentDue(): boolean {
      const terms = this.getCustomer.terms;
      if (terms) {
        const terms_object = this.getTerms.find(
          (item: any) => item.terms_id === terms,
        );
        return parseInt(terms_object?.net_days) <= 1;
      } else {
        return true;
      }
    },
    isPayout(): boolean {
      return this.getOrder.some((ar: any) => ar.type === "PO");
    },
    mainTabs(): boolean {
      return (
        this.$route.fullPath !== "/pos/payment" &&
        this.$route.fullPath !== "/pos/confirmation" &&
        this.$route.fullPath !== "/pos/ship" &&
        this.$route.fullPath !== "/pos/shipping"
      );
    },
    hasPosOrderCodes(): boolean {
      return this.posOrderCodes && this.posOrderCodes.length > 0;
    },
    customerModuleAccess(): boolean {
      return this.getWebAccess("CUST.Q");
    },
    showPartsInformation(): boolean {
      return this.getCurrentActivity === "PARTS";
    },
    showOrderInformation(): boolean {
      return this.getCurrentActivity === "ORDERS";
    },
    showInvoiceInformation(): boolean {
      return this.getCurrentActivity === "INVOICES";
    },
    partNumberLabel(): string {
      return this.getFieldLabel("PARTS", FDICT_PARTS.PART_NO, "Part #");
    },
    bookDateLabel(): string {
      return this.getFieldLabel("SO", FDICT_SO.BOOK_DATE, "Book Date");
    },
    shipDateLabel(): string {
      return this.getFieldLabel("SO", FDICT_SO.DATE, "Ship Date");
    },
    checkingRegister(): boolean {
      return this.isCheckingLocalService;
    },
    showLocalServiceError(): boolean {
      return this.isLocalServiceError;
    },
    salesOrderLineItems(): LisItems[] {
      if (this.showPartsInformation) {
        return this.getSalesOrder.lis_items;
      } else {
        return [];
      }
    },
    displayFields(): Field[] {
      const posOrderFields = this.posExtraInfoItems.map(
        (data: any) => data.pos_extra_info,
      );

      if (posOrderFields.length === 0) {
        return this.customFDictFields("SO");
      } else {
        return this.getFdictFields("SO", null, true).filter((field: Field) =>
          posOrderFields.includes(field.field_no),
        );
      }
    },
    orderTypeDesc(): string {
      return (
        this.salesOrderTypes.find(
          (item: any) => item.valid_so_type === this.getSalesOrder.type,
        )?.so_type_code_description || ""
      );
    },
    showOrderOptions(): boolean {
      return this.$route.name === "Shipping Information";
    },
    isPriceLocked(): boolean {
      if (this.getCustomer && this.getCustomer.code) {
        return this.isPriceCodeLocked(this.getCustomer.code);
      }
      return true;
    },
    remainingBalance(): any {
      let tenderAmount = 0;
      this.getTenders.forEach((tender: any) => {
        tenderAmount += parseFloat(tender.check_amount);
      });
      return (
        Math.round((parseFloat(this.total) - tenderAmount) * 100) / 100
      ).toFixed(2);
    },
    isCash(): boolean {
      let cashAmount = 0;
      this.getTenders.forEach((tender: any) => {
        if (tender.payment_type === "CA") {
          cashAmount += parseFloat(tender.check_amount);
        }
      });
      return cashAmount + parseFloat(this.remainingBalance) >= 0;
    },
    disabled(): boolean {
      const invoiceCount = this.getOrder.filter(
        (part: any) => part.ar_id,
      ).length;
      const orderCount = this.getOrder.filter((part: any) => part.so_id).length;

      const orderInfoPrecedesCreateOrder =
        this.orderInformationPrecedesCreateOrder &&
        this.showPartsInformation &&
        !this.$route.fullPath.includes("/pos/shipping");
      const isPayoutWithMultipleOrders =
        this.submitText === "Payout" && this.getOrder?.length > 1;
      const isSubmitPaymentWithoutTenders =
        this.submitText === "Submit payment(s)" &&
        this.isPayout &&
        this.getTenders.length < 1;
      const noValidInvoiceOrOrder =
        (this.showInvoiceInformation && !invoiceCount) ||
        (this.showOrderInformation && !orderCount);
      //const noPartsOrder = this.showPartsInformation && this.getSalesOrder?.lis_items == 0;

      return (
        noValidInvoiceOrOrder ||
        orderInfoPrecedesCreateOrder ||
        isPayoutWithMultipleOrders ||
        isSubmitPaymentWithoutTenders ||
        this.loadingOrderButton
      );
    },
    subTotal() {
      if (this.showPartsInformation) {
        let sum = 0;

        if (
          !this.getSalesOrder.lis_items ||
          this.getSalesOrder.lis_items.length === 0
        ) {
          return sum.toFixed(2);
        }

        this.getSalesOrder?.lis_items?.forEach((lineItem: any) => {
          sum +=
            parseFloat(lineItem.li_order_qtys) *
              parseFloat(lineItem.li_prices) || 0;
        });
        return sum.toFixed(2);
      } else {
        let sum = 0;
        this.getOrder.forEach((part: any) => {
          const quantity = part.quantity > 1 ? part.quantity : 1;
          const price = parseFloat(part.price) || 0;
          sum += price * quantity || 0;
          sum += parseFloat(part.order_amount) || 0;
          sum += parseFloat(part.balance) || 0;
        });
        return sum.toFixed(2);
      }
    },
    total(): string {
      if (
        this.getPosAutoValidate &&
        this.showOrderAmount &&
        this.showOrderSubTotal
      ) {
        return this.getSalesOrder.order_amount;
      } else {
        let sum = 0;
        if (this.showPartsInformation) {
          if (
            !this.getSalesOrder.lis_items ||
            this.getSalesOrder.lis_items.length === 0
          ) {
            return sum.toFixed(2);
          }
          this.getSalesOrder?.lis_items?.forEach((lineItem: any) => {
            const quantity = parseFloat(lineItem.li_order_qtys);
            const price = parseFloat(lineItem.li_prices) || 0;
            const discount = parseFloat(lineItem.li_discs || "0") / 100;
            sum += (price - price * discount) * quantity || 0;
          });
        } else if (this.showInvoiceInformation) {
          this.getOrder.forEach((part: any) => {
            sum += parseFloat(part.balance) || 0;
          });
        } else if (this.showOrderInformation) {
          this.getOrder.forEach((part: any) => {
            sum += parseFloat(part.order_amount) || 0;
          });
        }
        return sum.toFixed(2);
      }
    },
    totalParts(): number {
      let total = 0;
      if (this.showPartsInformation) {
        this.getSalesOrder?.lis_items?.forEach((lineItem: any) => {
          total += parseFloat(lineItem.li_order_qtys) || 0;
        });
      }
      return total;
    },
    discountTotal(): string {
      let sum = 0;
      if (this.showPartsInformation) {
        let discountTotal = 0;
        this.getSalesOrder?.lis_items?.forEach((lineItem: any) => {
          const quantity = parseFloat(lineItem.li_order_qtys);
          const price = parseFloat(lineItem.li_prices) || 0;
          const discount = parseFloat(lineItem.li_discs || "0") / 100;
          discountTotal += price * discount * quantity || 0;
        });

        if (discountTotal) {
          return discountTotal.toFixed(2);
        } else {
          return "";
        }
      } else {
        const disc = parseFloat(this.getCustomer?.disc) / 100 || 0.0;
        this.getOrder.forEach((part: any) => {
          const quantity = part.quantity > 1 ? part.quantity : 1;
          const price = parseFloat(part.price) || 0;
          sum += price * disc * quantity || 0;
        });

        if (sum) {
          return sum.toFixed(2);
        } else {
          return "";
        }
      }
    },
    totalWithTax(): string {
      if (this.showPartsInformation) {
        let sum = parseFloat(this.total) + (parseFloat(this.getTaxAmount) || 0);
        return sum.toFixed(2);
      } else {
        let sum = parseFloat(this.total) + (parseFloat(this.getTaxAmount) || 0);
        return sum.toFixed(2);
      }
    },

    severity() {
      if (this.submitText === "Payout") {
        return "background-color: red !important";
      } else {
        return "";
      }
    },
    computedSteps(): any {
      const steps = [
        {
          label: this.getCustomer ? "Select New Customer" : "Select Customer",
          to: "/pos/customers",
          visible: true,
          disabled: this.$route.fullPath === "/pos/confirmation",
          index: 1,
        },
        {
          label: "Select Parts",
          to: "/pos/parts",
          visible: this.showPartsInformation,
          disabled:
            this.getCustomer === null ||
            this.getCustomer === "" ||
            this.$route.fullPath === "/pos/confirmation" ||
            this.$route.fullPath === "/pos/order-created" ||
            (this.$route.fullPath === "/pos/shipping" &&
              this.orderInformationPrecedesCreateOrder &&
              !this.getSalesOrder.so_id) ||
            this.$route.fullPath === "/pos/customers",
          index: this.orderInformationPrecedesCreateOrder ? 3 : 2,
        },
        {
          label: "Select Invoices",
          to: "/pos/invoices",
          visible: this.showInvoiceInformation,
          disabled:
            this.getCustomer === null ||
            this.getCustomer === "" ||
            this.$route.fullPath === "/pos/confirmation",
          index: this.orderInformationPrecedesCreateOrder ? 4 : 3,
        },
        {
          label: "Select Orders",
          to: "/pos/orders",
          visible: this.showOrderInformation,
          disabled:
            this.getCustomer === null ||
            this.getCustomer === "" ||
            this.$route.fullPath === "/pos/confirmation",
          index: this.orderInformationPrecedesCreateOrder ? 5 : 4,
        },
        {
          label: this.getSalesOrder.so_id
            ? "Update Order Information"
            : "Add Order Information",
          to: "/pos/shipping",
          visible: this.showPartsInformation,
          disabled:
            !Array.isArray(this.getSalesOrder.lis_items) ||
            (this.getSalesOrder.lis_items?.length === 0 &&
              !this.orderInformationPrecedesCreateOrder) ||
            this.$route.fullPath === "/pos/order-created" ||
            !this.getCustomer ||
            this.$route.fullPath === "/pos/confirmation",
          index: this.orderInformationPrecedesCreateOrder ? 2 : 5,
        },
        {
          label: "Payment",
          to: "/pos/payment",
          disabled:
            !Array.isArray(this.getOrder) ||
            this.getOrder.length === 0 ||
            this.$route.fullPath === "/pos/confirmation",
          visible: !this.noReg && !this.showOrderInformation,
          index: 6,
        },
        {
          label: "Confirmation",
          to: "/pos/confirmation",
          visible: true,
          disabled: true,
          index: 7,
        },
      ];
      const visibleSteps = steps
        .filter((step) => step.visible === true)
        .sort((a, b) => a.index - b.index);
      return visibleSteps;
    },
    noReg(): boolean {
      return (
        (!this.getRegisterOptions ||
          this.getRegisterOptions.length === 0 ||
          this.forceUserMode) &&
        this.showPartsInformation
      );
    },
    computedItems(): any {
      const filteredItems = this.items.filter((item: any) => {
        if (item.label === "Parts") {
          item.to = this.orderInformationPrecedesCreateOrder
            ? "/pos/shipping"
            : "/pos/parts";
          return !this.hidePartsTab;
        }
        if (
          item.label === "Invoices" ||
          item.label === "Payment Methods on File" ||
          item.label === "Receipt History"
        ) {
          return this.getAccess(item.to);
        } else {
          return true;
        }
      });

      return filteredItems.map((item) => {
        if (item.label === "Payment Methods on File") {
          return {
            ...item,
            visible: !(this.getCustomer === null || this.getCustomer === ""),
          };
        }
        return item;
      });
    },
    hasPaymentAccess(): boolean {
      return this.getAccess("/pos/payment");
    },
    submitText() {
      let text = "Ship Order";
      if (this.$route.fullPath === "/pos/invoices") {
        if (this.posAllowPayout) {
          text = "Payout";
        } else {
          text = "Continue to Payment";
        }
        if (this.getOrder.length === 0 || this.getOrder === null) {
          text = "Select Invoice to Continue";
        } else {
          this.getOrder.forEach((ar: any) => {
            if (parseFloat(ar.balance) > 0) {
              text = "Continue to Payment";
            }
          });
        }
      } else if (this.$route.fullPath === "/pos/payment") {
        text = "Submit payment(s)";
      } else if (
        this.$route.fullPath === "/pos/shipping" &&
        this.orderInformationPrecedesCreateOrder
      ) {
        text = "Select Parts";
      } else if (
        this.$route.fullPath === "/pos/parts" &&
        !this.orderInformationPrecedesCreateOrder
      ) {
        if (this.getSalesOrder.so_id) {
          text = "Update Order Information";
        } else {
          text = "Add Order Information";
        }
      } else if (
        (this.$route.fullPath === "/pos/shipping" &&
          !this.orderInformationPrecedesCreateOrder) ||
        (this.$route.fullPath === "/pos/parts" &&
          this.orderInformationPrecedesCreateOrder)
      ) {
        if (this.getSalesOrder.so_id) {
          text = "Update Order";
        } else {
          text = "Create Order";
        }
      }
      return text;
    },
    isRegisterMode(): boolean {
      return (
        this.getRegisterOptions &&
        this.getRegisterOptions.length > 0 &&
        !this.forceUserMode
      );
    },
    showEntryUserLogin(): boolean {
      const isEnteringOrder =
        this.$route.fullPath === "/pos/parts" ||
        this.$route.fullPath === "/pos/shipping";

      return (
        this.getPosRequireEntryUserAuthentication &&
        isEnteringOrder &&
        this.getEntryUser === null
      );
    },
    showInvoicesSplitButton(): boolean {
      return (
        this.getCustomer &&
        this.$route.name !== "Parts" &&
        this.$route.fullPath !== "/pos/confirmation" &&
        this.$route.fullPath !== "/pos/pof" &&
        this.$route.fullPath !== "/pos/orders" &&
        this.$route.fullPath !== "/pos/receipt-history"
      );
    },
    isRefundDisabled(): boolean {
      return this.getOrder?.length !== 1 || +this.subTotal < 0;
    },
    customerAddresses(): ShipItem[] {
      const customerAddress = Utils.getCustomerMailAddress(this.getCustomer);
      const shipToItems = this.getCustomer.ship_seq_items || [];
      if (this.hideUseNewAddressButton) {
        return [...shipToItems];
      } else {
        return [customerAddress, ...shipToItems];
      }
    },
    addresses(): ShipItem[] {
      const orderAddress = (this.getOrder[0] as SalesOrder).shipItemAddress;
      const shipToItems = this.customerAddresses.filter(
        (item: ShipItem) => !orderAddress.compareTo(item),
      );

      if (this.getOrder.length === 0) {
        return shipToItems;
      }

      return [orderAddress, ...shipToItems];
    },
    posDesc(): string {
      const desc = this.posOrderCodes.find(
        (item: any) =>
          this.getSalesOrder.pos_order_code === item.pos_order_code,
      )?.pos_order_desc;
      return desc;
    },
  },
  async created() {
    this.getActiveTab();
    if (parseFloat(this.getUser.security_level) >= 80) {
      this.adminAccess = true;
    }
    if (
      this.getTransTypes === undefined ||
      this.getTransTypes === null ||
      this.getTransTypes.length === 0
    ) {
      this.fetchControls({
        id: "PSO",
        procedure: "PSO.CONTROL",
        filename: "CONTROL",
        getter: "control/getTransactionTypes",
      });
    }

    if (this.getCats === null || this.getCats.length === 0) {
      this.fetchControls({
        Client: "",
        id: "CAT",
        procedure: "CAT.CONTROL",
        filename: "CONTROL",
        getter: "control/getCategoryItems",
      });
    }
    //this.loadRegisters();
    this.onFilterRegOptionsList();

    this.fetchCoControl().finally(() => {
      this.setCoCode();
      this.getMrkControl({
        client: this.getClient,
        selectedCode: this.getSelectedCoCode,
      }).then(() => {
        this.getRegMode();
      });
    });

    this.custIdFields = this.controlFieldValues("CUST", "0");

    this.fetchTerms();

    if (this.getCustomer) {
      this.updateOrderInformation();
    }
    if (
      this.isCash &&
      this.getRegister?.default_overage_change_for_cash === "Y"
    ) {
      this.oa = false;
    }

    if (!this.posDisableStax) {
      this.fetchSalesTaxCodes();
    }

    this.fetchShipControl();

    this.fetchReps();
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeUnmount() {
    EventBus.off(AppEvents.PosFocusPartSearch);
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  async mounted() {
    // ToDo: we should really us using the session.user_id , no need for POS to have it's own copy
    this.setUser(this.getUser.user_id);

    if (this.getCustomer) {
      this.updateOrderInformation();
    }
  },
  data() {
    return {
      isOrderHeaderValid: false,
      selectedLineItems: [] as any,
      newInvoice: "",
      isOnline: navigator.onLine,
      unsavedOrders: [],
      continueToPayment: false,
      showContinueToPaymentDialog: false,
      checkingPosMode: true,
      forceUserMode: false,
      continueTransactionLabelItems: [] as any,
      posCartItems: {} as PosCartItems,
      sectionsStatus: {
        extraInformation: true,
        additionalOrderInfo: true,
      },
      custIdFields: [] as any,
      posOrderSummaryFields: [] as any,
      invalidSubmit: false,
      showAdminLogin: false,
      adminAccess: false,
      admin_pwd: "",
      admin_user: "",
      salesOrderFdict: {} as Fdict,
      confirmLineItemChangesMessage: "",
      visibleOrderPDFDialog: false,
      showOrderConfirmationDialog: false,
      isLoadingRegOptions: false,
      isLoadingRegister: false,
      loadingOrderButton: false,
      isLoadingInvoice: false,
      isLoadingCustomer: false,
      showClearOrderDialog: false,
      showClearInvoicesDialog: false,
      printerOptions: {} as any,
      showSelectReceiptPrinter: false,
      receiptPrinter: "",
      selectedLineItemIndex: -1,
      filteredRegOptions: [] as any,
      reg: "",
      openAmt: 0,
      transaction: {} as any,
      modalValues: {} as { [k: string]: any },
      displayDataModal: false,
      showAmountModal: false,
      updatingAr: {} as Invoice,
      showTenderAmountModal: false,
      updatingTndr: {} as any,
      tndrIndex: 0,
      active: 0,
      currentSo: "",
      cfeeResp: "",
      oa: true,
      newTndrAmt: "",
      newInvAmt: "",
      newInvAmtMin: 0,
      newInvAmtMax: 0,
      showOIPrompt: false,
      showRoaModal: false,
      showNoteModal: false,
      payNote: "",
      roaCharge: "",
      roaNote: "",
      oiNote: "",
      oiCharge: "",
      registerFingerprint: "",
      registerHostname: "",
      isCheckingLocalService: false,
      isLocalServiceError: false,
      shipAddressIndex: -1,
      page: 0,
      setFirst: false,
      showAddAddressDialog: false,
      partsButtonItems: [
        {
          label: "Continue To Payment",
          icon: "pi pi-angle-double-right",
          class: "continue-to-payment-btn",
          disabled: this.getCustomer && this.$route.name === "Parts",
          command: () => {
            this.submit();
          },
        },
      ],
      shipOptions: [
        {
          label: "Ship Partial Order",
          command: () => {
            this.partialOrder();
          },
        },
      ],
      invoiceOptions: [
        {
          label: "Process Refund",
          disabled: () => this.isRefundDisabled,
          command: () => {
            this.processRefund();
          },
        },
      ],
      otherActions: [
        {
          label: "Payment to Account",
          command: () => {
            this.setCurrentActivity("INVOICES");
            this.roa();
          },
        },
        {
          label: "Other Income",
          command: () => {
            this.setCurrentActivity("INVOICES");
            this.oi();
          },
        },
      ],
      items: [
        {
          label: "Parts",
          to: "/pos/shipping",
        },
        {
          label: "Orders",
          to: "/pos/orders",
        },
        {
          label: "Invoices",
          to: "/pos/invoices",
        },
        {
          label: "Receipt History",
          to: "/pos/receipt-history",
        },
        {
          label: "Payment Methods on File",
          to: "/pos/pof",
        },
      ],
      showInvoiceCartDialog: false,
      selectedInvoiceLineItems: [],
    };
  },
  methods: {
    ...mapActions({
      setSalesOrder: "pos/setSalesOrder",
      saveSalesOrder: "pos/saveSalesOrder",
      fetchControls: "control/fetchControl",
      fetchCustomerControls: "customerInquiry/getControls",
      changeTitle: "title/changeTitle",
      setReg: "pos/setRegister",
      updateReg: "pos/updateRegister",
      setCust: "pos/setCustomer",
      clearCust: "pos/clearCustomer",
      clearReg: "pos/clearRegister",
      clearOrder: "pos/clearOrder",
      replaceOrder: "pos/replaceOrder",
      closeClearRegister: "pos/closeClearRegister",
      clearPos: "pos/clearPOS",
      updateInvAmt: "pos/updateInvoiceAmount",
      removeFromOrder: "pos/removeFromOrder",
      clearTenders: "pos/clearTenders",
      removeTndr: "pos/removeTender",
      updateTenderAmt: "pos/updateTenderAmount",
      addOaLine: "pos/addOaLine",
      addChange: "pos/addChange",
      setPriceStrategy: "pos/setPriceStrategy",
      setChangeAmount: "pos/setChangeAmount",
      setTranId: "pos/setTranId",
      setUser: "pos/setUser",
      addNotification: "notification/add",
      setSelectedCoCode: "customerInquiry/setSelectedCoCode",
      getFdicts: "fdict/fetchFdict",
      clearRacks: "pos/clearRacks",
      clearTaxAmount: "pos/clearTaxAmount",
      getOrderPDF: "sales/getOrderPDF",
      setLastOrderId: "pos/setLastOrderId",
      setLastOrderCustomerContacts: "pos/setLastOrderCustomerContacts",
      setLastOrder: "pos/setLastOrder",
      getMrkControl: "mrkControl/getMrkControl",
      setStoredCartItems: "pos/setStoredCartItems",
      fetchHardwareRequest: "pos/fetchHardwareRequest",
      fetchRegisterHardware: "pos/fetchRegisterHardware",
      updateRegisterHardware: "pos/updateRegisterHardware",
      getTaxes: "pos/getTaxes",
      addUnsavedOrder: "pos/addUnsavedOrder",
      removeUnsavedOrder: "pos/removeUnsavedOrder",
      setRegisterPrinter: "pos/setRegisterPrinter",
      setCurrentActivity: "pos/setCurrentActivity",
      validateSalesOrder: "pos/validateSalesOrder",
      fetchTerms: "terms/fetchTerms",
      setCustomer: "pos/setCustomer",
      getCustomer: "customer/getCustomer",
      fetchSalesTaxCodes: "stax/fetchSalesTaxCodes",
      fetchReps: "rep/fetchReps",
      fetchShipControl: "shipControl/getShipControl",
      setEntryUser: "pos/setEntryUser",
      fetchCoControl: "coControl/fetchCoControl",
      fetchUnsavedOrders: "pos/getUnsavedOrders",
      setRegisterOptions: "pos/setRegisterOptions",
      clearStoredCartItems: "pos/clearStoredCartItems",
    }),
    getFieldValue(field: Field): any {
      let value: any = "";

      if (field.custom_field) {
        value = this.getSalesOrder.custom_fields[field.json_name] ?? "";
      } else if (field.json_name + "_items" in this.getSalesOrder) {
        const parentField = this.getSalesOrder[field.json_name + "_items"];
        if (parentField) {
          value = parentField
            .map((item: any) => item[field.json_name])
            .join("\n");
        }
      } else {
        value = this.getSalesOrder[field.json_name] ?? "";
      }

      const valueItems = field.valid_value_items;
      if (field.boolean) {
        value = Utils.booleanCheck(value) ? "Yes" : "No";
      } else if (valueItems && valueItems.length > 0) {
        if (!Utils.booleanCheck(field.multi_line)) {
          value = [value];
        } else if (!value) {
          value = [];
        }

        value = value.map((item: any) => {
          const validValue = valueItems.find(
            (validItem) => validItem.valid_value === item,
          );
          return validValue ? validValue.valid_desc : item;
        });

        value = value.join("\n");
      }

      return value;
    },
    handleValidateInput(field: Field) {
      const originalOrder = cloneDeep(this.getOldSalesOrder);
      this.validateSalesOrder({
        oldSalesOrder: originalOrder,
        metaData: {
          validate_only: "Y",
          validate_reason: field.dict_name,
          validate_field_no: field.field_no,
        },
      });
    },
    checkSubtotalItemExists(field: string): boolean {
      const modifiedField = field.replace(/_/g, ".").toUpperCase();

      return (
        this.correlativeExists("SO", modifiedField) ||
        this.fieldExists("SO", modifiedField) ||
        this.correlativeExists("SO", field) ||
        this.fieldExists("SO", field)
      );
    },
    async handleLineItemChanged() {
      if (!this.getAutosaveDraftOrder) {
        return;
      }

      this.updateOrderInformation();

      const orderId = this.getCurrentUnsavedOrder.id;
      const date = this.getCurrentUnsavedOrder.date_created;

      const order = {
        order: cloneDeep(this.getSalesOrder),
        oldOrder: cloneDeep(this.getOldSalesOrder),
        customer_name: this.getCustomer.name,
        date_created: date ? date : new Date(),
        id: orderId ? orderId : (crypto as any).randomUUID(),
        racks: cloneDeep(this.getRacks),
      };

      await this.addUnsavedOrder(order);
      await this.setSalesOrder(order);
    },
    isFieldReadOnly(field: Field): boolean {
      return isFieldReadOnly(field);
    },
    processRefund() {
      this.selectedInvoiceLineItems = this.getOrder[0].li_items; // TODO: For now we only support refund on one invoice
      this.showInvoiceCartDialog = true;
    },
    openCartDialog(lineItemIndex: number) {
      this.selectedLineItemIndex = lineItemIndex;
      this.selectedLineItems = this.getSalesOrder;
      (this.$refs.cartDialog as any).handleOpenDialog();
    },
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    closePosDialog() {
      this.selectedLineItemIndex = -1;
    },
    closeInvoiceCartDialog() {
      this.showInvoiceCartDialog = false;
    },
    async saveNewAddress(data: any, addNewAddress = false) {
      if (addNewAddress) {
        let [oldCust, newCust] = [{}, {}];

        if (!this.getCustomer.ship_seq_items) {
          this.getCustomer.ship_seq_items = [];
        }

        let oldShipSeq = [...this.getCustomer.ship_seq_items];
        let nextSeqItemId = (
          this.getCustomer.ship_seq_items
            .map((element: any) => element.ship_seq)
            .reduce((a: any, b: any) => Math.max(a, b), -Infinity) + 1
        ).toString();

        if (nextSeqItemId === "NaN") {
          nextSeqItemId = (
            this.getCustomer.ship_seq_items.length + 1
          ).toString();
        }

        data.ship_seq = nextSeqItemId;

        newCust = {
          cust_id: this.getCustomer.cust_id,
          ship_seq_items: [...oldShipSeq, data],
        };
        oldCust = {
          cust_id: this.getCustomer.cust_id,
          ship_seq_items: [...oldShipSeq],
        };

        await store
          .dispatch("customerInquiry/updateCustomer", {
            custId: this.getCustomer.cust_id,
            oldCust,
            newCust,
            Client: this.getClient,
            populateBlankMailingAddress: this.posAutoCreateCust ? "Y" : "",
          })
          .then((response) => {
            customerService
              .getCustomer(
                this.getCustomer.cust_id,
                this.getClient,
                "contact_email contact_name rep_name",
              )
              .then((response: any) => {
                this.setCustomer({ cust_items: [response] });
                (this.getSalesOrder as SalesOrder).setOrderAddress(
                  this.getCustomer,
                  data,
                );
              })
              .catch((response) => {
                this.addNotification({
                  message: `An error occurred while getting the customer.`,
                  type: NotificationType.ERROR,
                });
              });
          })
          .catch((response) => {
            this.addNotification({
              message: `An error occurred while adding the address.`,
              type: NotificationType.ERROR,
            });
          });
      } else {
        (this.getSalesOrder as SalesOrder).setOrderAddress(
          this.getCustomer,
          data,
        );
      }

      this.getTaxes();
      this.closeAddAddressDialog();
    },
    handleOnClose() {
      this.setFirst = true;
    },
    handleRowClick(event: any) {
      this.setFirst = false;
      this.setShipAdr(event.data);
    },
    setShipAdr(address: any) {
      //this.handleUpdatePage(0);
      Utils.setOrderAddress(this.getCustomer, this.getOrder[0], address);
    },
    openAddAddressDialog() {
      this.showAddAddressDialog = true;
    },
    closeAddAddressDialog() {
      this.showAddAddressDialog = false;
    },
    handleClick() {
      (this.$refs.splitButton as any)?.$refs.button.$attrs.onClick();
    },
    selectSalesOrderPart(event: any, lineItemIndex: any) {
      this.selectedLineItemIndex = lineItemIndex;
    },
    changeCompanyCode(event: any) {
      const originalOrder = cloneDeep(this.getOldSalesOrder);
      this.setSelectedCoCode(event.value);
      this.getSalesOrder.co_code = event.value;
      this.validateSalesOrder({
        oldSalesOrder: originalOrder,
        metaData: { validate_only: "Y", validate_reason: "CO_CODE" },
      });
    },
    localServiceErrorLogout() {
      store.dispatch("session/logout", null, { root: true });
    },
    retryRegisterFetch() {
      this.checkLocalService();
    },
    setReceiptPrinter(data: any) {
      if (data && data !== "") {
        this.showSelectReceiptPrinter = false;
        this.setRegisterPrinter(data);
      } else {
        this.addNotification({
          message: `Please select a printer.`,
          type: NotificationType.ERROR,
        });
      }
    },
    async checkLocalService() {
      this.isLocalServiceError = false;
      this.isCheckingLocalService = true;
      localDesktopService
        .fetchHardwareRequest(this.allowMixedMode)
        .then(async (response: any) => {
          if (response) {
            const localRegisterResponse = response as RegisterHardwareDetail;
            // use fingerprint to pull register value
            if (
              localRegisterResponse.fingerprint &&
              localRegisterResponse.fingerprint !== ""
            ) {
              this.registerFingerprint = localRegisterResponse.fingerprint;
              this.registerHostname = localRegisterResponse.hostname;
              this.fetchRegisterHardware(response.fingerprint)
                .then((response) => {
                  const hostRegisterResponse =
                    response.response as RegisterFetch;
                  if (
                    hostRegisterResponse.RegisterID &&
                    hostRegisterResponse.RegisterID !== ""
                  ) {
                    this.setReg(hostRegisterResponse.RegisterID)
                      .then(() => {
                        if (this.getRegister.status === "Closed") {
                          // if register was closed, make sure an existing order is cleared
                          this.clearPos();
                          this.clearCust();
                        }
                      })
                      .finally(() => {
                        this.isLoadingRegister = false;
                        this.isCheckingLocalService = false;
                      });
                  } else {
                    //if user is admin display reg options
                    //else display inner login to credential admin and display reg options
                    if (!this.adminAccess) {
                      this.isCheckingLocalService = false;
                      this.showAdminLogin = true;
                    }
                  }
                })
                .catch((Error) => {
                  this.isCheckingLocalService = false;
                  if (
                    localRegisterResponse.tcsRegisterID &&
                    localRegisterResponse.tcsRegisterID !== ""
                  ) {
                    // try using the TCS RegisterID
                    const updateRegObj = {
                      SessionID: null,
                      Fingerprint: localRegisterResponse.fingerprint,
                      Name: localRegisterResponse.hostname,
                      RegisterID: localRegisterResponse.tcsRegisterID,
                    };
                    this.updateRegisterHardware(updateRegObj).then(
                      (response) => {
                        if (response.error && response.error !== "") {
                          if (!this.adminAccess) {
                            this.isCheckingLocalService = false;
                            this.showAdminLogin = true;
                          }
                        }
                      },
                    );
                  } else {
                    if (!this.adminAccess) {
                      this.isCheckingLocalService = false;
                      this.showAdminLogin = true;
                    }
                  }
                });
            }
          }
          // fetch printers
          await localDesktopService.fetchPrinterList().then((response: any) => {
            this.printerOptions = response.printerlist.map((item: any) => {
              return {
                name: item,
                id: item,
              };
            });
          });
          // if no printer is selected in Settings, prompt for it
          if (
            this.getPOSRegisterPrinter === null ||
            this.getPOSRegisterPrinter === ""
          ) {
            this.showSelectReceiptPrinter = true;
          }
        })
        .catch((error) => {
          this.isLoadingRegister = false;
          this.isCheckingLocalService = false;
          if (this.allowMixedMode) {
            this.forceUserMode = true;
          } else {
            this.isLocalServiceError = true;
          }
        });
    },
    until(conditionFunction: any) {
      const poll = (resolve: any) => {
        if (conditionFunction()) resolve();
        else setTimeout((_: any) => poll(resolve), 400);
      };
      return new Promise(poll);
    },
    oi() {
      this.showOIPrompt = true;
    },
    roa() {
      this.showRoaModal = true;
    },
    partialOrder() {
      this.$router.push("/pos/ship");
    },
    checkAndConfirmOrder() {
      if (this.isOrderHeaderValid) {
        if (this.getSalesOrder.so_id) {
          this.updateOrder();
        } else {
          this.createOrder();
        }
      } else {
        this.addNotification({
          message: `Required fields missing. Please review the order.`,
          type: NotificationType.ERROR,
        });
        this.invalidSubmit = true;
      }
    },
    clearCurrentOrder() {
      this.clearOrder();
      this.clearTenders();
      this.clearTaxAmount();
      this.clearMenuItems();
      this.clearRacks();
      this.showClearOrderDialog = false;
      this.$router.push("/pos/parts");
    },
    clearInvoicesOrder() {
      this.clearOrder();
      this.showClearInvoicesDialog = false;
    },
    getSOTypes() {
      this.getMrkControl({
        client: this.getClient,
        selectedCode: this.getSelectedCoCode,
      })
        .then((resp) => {
          this.setPriceStrategy(resp.pos_price_strategy);

          const posSummaryItemIds = this.posOrderSummaryItems.map(
            (item: any) => item.pos_order_summary_id,
          );
          this.posOrderSummaryFields = this.customFDictFields("CUST")
            .filter((item: any) => posSummaryItemIds.includes(item.field_no))
            .map((item: any) => {
              return {
                ...item,
                label: item.desc_items.map((desc: any) => desc.desc).join(" "),
                json_name: item.json_name,
              };
            });
        })
        .finally(() => {
          this.clearMenuItems();
          this.updateOrderInformation();
        });
    },
    getSectionIconClass(
      status: { [key: string]: boolean },
      section: "extraInformation",
    ) {
      return status[section]
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    saveOrder() {
      this.updateOrderInformation();

      const orderId = this.getCurrentUnsavedOrder.id;

      this.addUnsavedOrder({
        order: cloneDeep(this.getSalesOrder),
        oldOrder: cloneDeep(this.getOldSalesOrder),
        customer_name: this.getCustomer.name,
        date_created: new Date(),
        id: orderId ? orderId : (crypto as any).randomUUID(),
        racks: cloneDeep(this.getRacks),
      });

      this.addNotification({
        message: `Successfully Saved Order`,
        type: NotificationType.SUCCESS,
      });
      this.clearTaxAmount();
      this.clearMenuItems();
      this.clearOrder();
      this.resetCustomFields();
      this.$router.push("/pos/customers");
      this.showOrderConfirmationDialog = false;
    },
    updateOrderInformation() {
      if (!this.getCustomer || !this.getSalesOrder) {
        // Don't update order information if customer or order is not available
        return;
      }

      if (this.hasPosOrderCodes) {
        const posCode = this.posOrderCodes.find((item: any) =>
          this.getSalesOrder.pos_order_code
            ? item.pos_order_code === this.getSalesOrder.pos_order_code
            : item.so_type_code === this.getSalesOrder.type &&
              item.ship_via_code === this.getSalesOrder.ship_via,
        );

        if (posCode) {
          this.getSalesOrder.pos_order_code = posCode.pos_order_code;
        }
      }

      this.getSalesOrder.order_amount = this.total;
      this.getSalesOrder.reg = this.getRegister?.reg_id;

      this.getSalesOrder.lis_items?.forEach((item: any) => {
        item.li_sched_dates_items?.forEach((date: any) => {
          date.li_sched_dates = this.getSalesOrder.date;
        });
      });
    },
    createOrder() {
      this.loadingOrderButton = true;
      const contacts = this.getCustomer.contact_id_items;
      this.getSalesOrder.rep_items = [{ rep: this.getSalesOrder.rep }];
      this.updateOrderInformation();
      // Only reorder the ids if the order is not an existing order
      (this.getSalesOrder as SalesOrder).reorderLineItems();
      let meta = "";
      if (this.getRacks.length > 0) {
        meta = JSON.stringify({
          racks: this.getRacks,
          return_correlatives: "order_amount part_type po_status",
        });
      } else {
        meta = JSON.stringify({
          return_correlatives: "order_amount part_type po_status",
        });
      }
      const returnRec = "Y";
      salesService
        .postOrder(
          this.getSalesOrder,
          undefined,
          meta,
          returnRec,
          this.getEntryUser,
        )
        .then((response) => {
          let saleId = (response as any).recordId;
          let orderReturned = (response as any)?.record ?? null;

          const id = this.getCurrentUnsavedOrder.id || "";
          if (id) {
            this.removeUnsavedOrder(id);
          }

          this.clearRacks();
          this.clearTenders();
          this.setStoredCartItems({
            items: [],
            type: "orderParts",
          });
          this.clearTaxAmount();
          this.clearMenuItems();
          this.setLastOrderCustomerContacts(contacts);
          this.setLastOrderId(saleId);
          this.setLastOrder(orderReturned);
          this.clearOrder();
          this.resetCustomFields();
          if (this.posShowOrderConfirmationPage) {
            this.$router.push("/pos/order-created");
          } else {
            if ((response as any).record) {
              this.setStoredCartItems({
                items: [(response as any).record],
                type: "summaryOrder",
              });
            }

            this.setCurrentActivity("ORDERS");
            this.$router.push("/pos/orders");
            this.replaceOrder(this.getStoredCartItems.summaryOrder || []);

            // Initialize new order with the same customer.
            this.setSalesOrder({
              order: Utils.initNewOrderFromCustomer(
                this.getCustomer,
                this.getSelectedCoCode,
                this.getSalesTaxCodes,
              ),
              date_created: new Date(),
              racks: [],
            });
          }
        })
        .finally(() => {
          this.showOrderConfirmationDialog = false;
          this.loadingOrderButton = false;
        });
    },
    updateOrder() {
      this.loadingOrderButton = true;
      const contacts = this.getCustomer.contact_id_items;
      this.updateOrderInformation();
      let meta = "";
      if (this.getRacks.length > 0) {
        meta = JSON.stringify({ racks: this.getRacks });
      }

      // reorder lis_items so that they are always sent in ascending order of lis number
      this.getSalesOrder.lis_items = this.getSalesOrder.lis_items?.sort(
        (a: any, b: any) => a.lis - b.lis,
      );

      const payload = {
        newSalesOrder: this.getSalesOrder,
        oldSalesOrder: this.getOldSalesOrder,
      };
      salesService
        .updateOrder(this.getSalesOrder.so_id, payload)
        .then((response) => {
          let saleId = (response as any).recordId;
          let orderReturned = (response as any)?.record ?? null;

          const id = this.getCurrentUnsavedOrder.id || "";
          if (id) {
            this.removeUnsavedOrder(id);
          }

          this.addNotification({
            message: `Successfully Updated Order #${saleId}`,
            type: NotificationType.SUCCESS,
          });
          this.clearRacks();
          this.clearTenders();
          this.clearTaxAmount();
          this.clearMenuItems();
          this.setLastOrderCustomerContacts(contacts);
          this.setLastOrderId(saleId);
          this.setLastOrder(orderReturned);
          this.clearOrder();
          this.resetCustomFields();
          this.$router.push("/pos/order-created");
        })
        .catch((error) => {
          this.addNotification({
            message: error?.response?.data.error || "Error updating order",
            type: NotificationType.ERROR,
          });
        })
        .finally(() => {
          this.showOrderConfirmationDialog = false;
          this.loadingOrderButton = false;
        });
    },
    resetCustomFields() {
      this.getSalesOrder.custom_fields = {};
      this.customFDictFields("SO").forEach((field: Field) => {
        this.getSalesOrder.custom_fields[field.json_name as string] = null;
      });
    },
    regStatus(): boolean {
      if (this.getRegister !== "" && this.getRegister !== null)
        return this.getRegister.status === "Open";
      else return false;
    },
    addOIandPay() {
      this.showOIPrompt = false;
      this.replaceOrder([
        {
          ar_id: "OI",
          type: "OI",
          pay_note: this.oiNote,
          amount: parseFloat(this.oiCharge).toFixed(2).toString(),
          balance: parseFloat(this.oiCharge).toFixed(2).toString(),
        },
      ]);
      this.oiNote = "";
      this.oiCharge = "";
      this.$router.push("/pos/payment");
    },
    addROAandPay() {
      this.showRoaModal = false;
      this.replaceOrder([
        {
          ar_id: "OA",
          type: "OA",
          pay_note: this.roaNote,
          amount: parseFloat(this.roaCharge).toFixed(2).toString(),
          balance: parseFloat(this.roaCharge).toFixed(2).toString(),
          new_ar_id: "Y",
        },
      ]);
      this.roaNote = "";
      this.roaCharge = "";
      this.$router.push("/pos/payment");
    },
    removePart(event: any, index: any) {
      if (event) {
        event.stopPropagation();
      }
      this.removeFromOrder(index);
    },

    removeTender(event: any, index: any) {
      event.stopPropagation();
      this.removeTndr(index);
    },
    async submitPayment() {
      this.loadingOrderButton = true;
      if (this.isPayout) {
        //should only be one invoice and one tender
        let li = 1;
        let li_items = [] as any;
        this.getOrder.forEach((ar: any) => {
          li_items.push({
            li: li.toString(),
            arid: ar.ar_id,
            amount: parseFloat(ar.balance).toFixed(2).toString(),
            type: "PO",
            ar_app_amt: parseFloat(ar.balance).toFixed(2).toString(),
            pay_note: ar.pay_note,
          });
          li++;
        });
        let tenderList = [this.getTenders[0]];
        tenderList[0].register_no = this.getRegister?.reg_id;
        (tenderList[0].user = this.getUser.user_id),
          (tenderList[0].tran_type = "fmp");
        tenderList[0].li_items = li_items;
        tenderList[0].cust = this.getCustomer?.cust_id;
        paymentService
          .Pay(tenderList)
          .then((response: any) => {
            if (response.details) {
              this.setTranId(response.details[0].recordId);
              const index = this.getTenders.findIndex(
                (tender: any) => tender.payment_type === "CA",
              );
              if (index > -1) {
                if (response.details[index].data?.change_amount) {
                  this.setChangeAmount(
                    response.details[index].data?.change_amount,
                  );
                } else {
                  this.setChangeAmount(null);
                }
              }
            } else {
              this.setTranId(response.recordId);
            }
            if (
              response.error === null ||
              response.error === "" ||
              response.error === undefined
            ) {
              if (response.status === "success") {
                this.$router.push("/pos/confirmation");
              }
            }
          })
          .finally(() => {
            this.loadingOrderButton = false;
          });
        return;
      }

      let overageOAAmount = 0;

      if (this.remainingBalance < 0) {
        if (
          this.isCash &&
          this.getRegister?.default_overage_change_for_cash &&
          this.getRegister?.hide_overage_checkbox === "Y"
        ) {
          this.addChange(this.remainingBalance);
        } else {
          if (this.oa) {
            // save the remaining balance so it can be backed out from the OA if the payment fails
            overageOAAmount = this.remainingBalance;
            this.addOaLine(this.remainingBalance.toString());
          } else {
            this.addChange(this.remainingBalance);
          }
        }
      }
      // if there are not any tenders add a cash tender of $0
      if (this.getTenders.length === 0) {
        this.getTenders.push({
          payment_type: "CA",
          check_amount: 0,
          change_amt: 0,
        });
      }

      let tenderList = cloneDeep(this.getTenders);
      tenderList.forEach((tender: any, i: number) => {
        let li = 1;
        let invoices = [] as any;
        let tenderAmount = parseFloat(tender.check_amount);
        if (tender.change_amt) {
          tenderAmount -= parseFloat(tender.change_amt);
        }
        let previousTenders = 0;
        if (i > 0) {
          for (let t = 0; t < i; t++) {
            previousTenders +=
              parseFloat(tenderList[t].check_amount) -
              (parseFloat(tenderList[t].change_amt) || 0);
          }
        }

        let arList = this.getOrder
          .filter((ar: any) => ar.ar_id)
          .sort(
            (a: any, b: any) => parseFloat(a.balance) - parseFloat(b.balance),
          );
        arList.forEach((ar: any) => {
          if (previousTenders > 0) {
            if (previousTenders >= parseFloat(ar.balance)) {
              previousTenders = previousTenders - parseFloat(ar.balance);
            } else {
              let app_amount = parseFloat(ar.balance) - previousTenders;
              previousTenders = 0.0;
              if (tenderAmount >= app_amount) {
                invoices.push({
                  li: li.toString(),
                  arid: ar.ar_id,
                  amount: ar.balance,
                  type: ar.type,
                  ar_app_amt: app_amount.toFixed(2).toString(),
                  pay_note: ar.pay_note,
                  new_ar_id: ar.new_ar_id,
                });
                tenderAmount -= app_amount;
              } else {
                invoices.push({
                  li: li.toString(),
                  arid: ar.ar_id,
                  amount: ar.balance,
                  type: ar.type,
                  ar_app_amt: tenderAmount.toFixed(2).toString(),
                  pay_note: ar.pay_note,
                  new_ar_id: ar.new_ar_id,
                });
                tenderAmount = 0;
              }
            }
          } else if (tenderAmount > 0) {
            if (tenderAmount >= ar.balance) {
              invoices.push({
                li: li.toString(),
                arid: ar.ar_id,
                amount: ar.balance,
                type: ar.type,
                ar_app_amt: parseFloat(ar.balance).toFixed(2).toString(),
                pay_note: ar.pay_note,
                new_ar_id: ar.new_ar_id,
              });
              tenderAmount -= parseFloat(ar.balance);
            } else {
              invoices.push({
                li: li.toString(),
                arid: ar.ar_id,
                amount: ar.balance,
                type: ar.type,
                ar_app_amt: tenderAmount.toFixed(2).toString(),
                pay_note: ar.pay_note,
                new_ar_id: ar.new_ar_id,
              });
              tenderAmount = 0;
            }
          }
          li++;
        });
        tenderList[i].cust = this.getCustomer?.cust_id;
        tenderList[i].register_no = this.getRegister?.reg_id;
        tenderList[i].user = this.getUser.user_id;
        tenderList[i].tran_type = "fmp";
        tenderList[i].li_items = invoices;
      });

      // need to make sure all invoices are included.
      this.getOrder.forEach((ar: any) => {
        if (
          !tenderList.some((tender: any) =>
            tender.li_items.some((li: any) => li.arid === ar.ar_id),
          )
        ) {
          tenderList[0].li_items.push({
            li: tenderList[0].li_items.length + 1,
            arid: ar.ar_id,
            amount: ar.balance,
            type: ar.type,
            ar_app_amt: ar.balance,
            pay_note: ar.pay_note,
          });
        }
      });

      paymentService
        .Pay(tenderList)
        .then((response: any) => {
          if (response.details) {
            this.setTranId(response.details[0].recordId);
            const index = this.getTenders.findIndex(
              (tender: any) => tender.payment_type === "CA",
            );
            if (index > -1) {
              if (response.details[index].data?.change_amount) {
                this.setChangeAmount(
                  response.details[index].data?.change_amount,
                );
              } else {
                this.setChangeAmount(null);
              }
            }
          } else {
            this.setTranId(response.recordId);
          }
          if (
            response.error === null ||
            response.error === "" ||
            response.error === undefined
          ) {
            if (response.status === "success") {
              this.$router.push("/pos/confirmation");
            }
          } else {
            // back out automatically applied OA in case of a 200 response with error content.
            this.reverseOAAfterFailure(overageOAAmount);
            this.addNotification({
              message: `Failed to Process Payment. ${response.error}`,
              type: NotificationType.ERROR,
            });
          }
        })
        .catch((error) => {
          // back out automatically applied OA in case of a non 200 response.
          this.reverseOAAfterFailure(overageOAAmount);
        })
        .finally(() => {
          this.loadingOrderButton = false;
        });
    },
    async submitShip() {
      const originalOrder = cloneDeep(this.getOldSalesOrder);
      if (!this.isOrderHeaderValid) {
        this.addNotification({
          message: `Required fields missing. Please review the order.`,
          type: NotificationType.ERROR,
        });
        this.invalidSubmit = true;
        return;
      }
      if (!this.orderInformationPrecedesCreateOrder) {
        this.invalidSubmit = true;
        if (!this.hideDraftedOrders) {
          this.showOrderConfirmationDialog = true;
        } else {
          if (this.getSalesOrder.so_id) {
            this.updateOrder();
          } else {
            this.createOrder();
          }
        }
      } else {
        const response = await this.validateSalesOrder({
          oldSalesOrder: originalOrder,
          metaData: {
            validate_only: "Y",
            validate_reason: "PARTS_SELECTED",
          },
        });
        if (response.status === "success") {
          this.$router.push("/pos/parts");
        }
      }
    },
    async submitOrders() {
      if (this.prohibitOpenPoShipments && this.hasOpenPo) {
        this.addNotification({
          message: `Order contains open PO(s) and/or Non-Stock item(s).`,
          type: NotificationType.ERROR,
        });
        return;
      }
      this.loadingOrderButton = true;
      this.setStoredCartItems({
        items: [],
        type: "orderInvoice",
      });
      if (this.getOrder.length > 0) {
        const orders = cloneDeep(this.getOrder);
        for await (const order of orders) {
          this.loadingOrderButton = true;
          if (order.so_id) {
            const request = { so_id: order.so_id, ship_id: order.so_id };
            await Utils.until((_: any) => this.currentSo === "");
            await shipService
              .postShip(request)
              .then(async (response: any) => {
                if (response.status === "CFEE") {
                  //display prompt
                  this.currentSo = order.so_id;
                  await Utils.until((_: any) => this.currentSo === "");
                  if (this.cfeeResp !== "") {
                    await shipService
                      .postShip({
                        so_id: order.so_id,
                        ship_id: order.so_id,
                        fmp_cfee: this.cfeeResp,
                      })
                      .then((secondResp: any) => {
                        response = secondResp;
                        this.cfeeResp = "";
                      });
                  }
                }
                await Utils.until((_: any) => this.cfeeResp === "");
                if (response.status === "success") {
                  this.addNotification({
                    message: `Order #${order.so_id} has been finalized`,
                    type: NotificationType.SUCCESS,
                  });
                  this.clearTenders();
                  const list = cloneDeep(this.getStoredCartItems.summaryOrder);
                  const updatedList = list.filter(function (el: any) {
                    return el.so_id !== order.so_id;
                  });
                  this.setStoredCartItems({
                    items: cloneDeep(updatedList || []),
                    type: "summaryOrder",
                  });
                  this.replaceOrder(this.getStoredCartItems.summaryOrder);
                  //search invoice
                  await this.promptUser(response);
                  await Utils.selectOrderInvoice(
                    response,
                    () => {
                      this.loadingOrderButton = false;
                    },
                    () => {
                      this.handleShowKeepCustomerDialog();
                    },
                  );
                } else if (response.status !== "CFEE") {
                  this.addNotification({
                    message: `Failed to Finalize Order # ${order.so_id}. ${response.error}`,
                    type: NotificationType.ERROR,
                  });
                }
              })
              .catch((error) => {
                this.loadingOrderButton = false;
                this.addNotification({
                  message: `Failed to Finalize Order # ${order.so_id}. ${error.response.data.error}`,
                  type: NotificationType.ERROR,
                });
              })
              .finally(() => {
                this.loadingOrderButton = false;
              });
          }
        }
        if (this.getStoredCartItems.orderInvoice?.length > 0) {
          this.setCurrentActivity("INVOICES");
          this.replaceOrder(this.getStoredCartItems.orderInvoice || []);
          // route to payment
          //if it's a payout, add a paynote
          await this.applyPayoutLogic();
        }
      }
    },
    async submitInvoices() {
      if (
        this.submitText !== "Payout" &&
        this.submitText !== "Select Invoice to Continue"
      ) {
        this.$router.push("/pos/payment");
      } else {
        this.getSalesOrder.type = "CA";
        this.showNoteModal = true;
      }
    },
    submitParts() {
      if (!this.orderInformationPrecedesCreateOrder) {
        this.$router.push("/pos/shipping");
      } else {
        this.invalidSubmit = true;
        if (!this.hideDraftedOrders) {
          this.showOrderConfirmationDialog = true;
        } else {
          this.createOrder();
        }
      }
    },
    async submit() {
      if (this.$route.fullPath === "/pos/payment") {
        await this.submitPayment();
      } else if (this.$route.fullPath === "/pos/shipping") {
        this.submitShip();
      } else if (this.$route.fullPath === "/pos/orders") {
        await this.submitOrders();
      } else if (this.$route.fullPath === "/pos/invoices") {
        await this.submitInvoices();
      } else if (this.$route.fullPath === "/pos/parts") {
        this.submitParts();
      } else if (this.$route.fullPath === "/pos/ship") {
        this.partialShip();
      }
    },
    async applyPayoutLogic() {
      if (this.isPayout) {
        this.showNoteModal = true;
      } else {
        this.$router.push("/pos/payment");
      }
    },
    async promptUser(response: any) {
      if (!this.hasPaymentAccess) {
        this.loadingOrderButton = false;
        this.$router.push("/pos/customers");
      } else if (!this.isPaymentDue && this.posTermsRequirePayment) {
        this.newInvoice = response.record?.invoice_no || response.data?.invoice;
        this.showContinueToPaymentDialog = true;
        await Utils.until((_: any) => this.continueToPayment);
        this.showContinueToPaymentDialog = false;
        this.continueToPayment = false;
        this.newInvoice = "";
      }
    },
    async partialShip() {
      let lis = this.getOrder[0].lis_items;
      let mutatedLis = lis.map((li: any) => {
        return {
          ...li,
          qtys: li.li_ship_qtys,
        };
      });
      let shipped = false;
      let shipResponse = {} as any;
      if (lis.filter((li: any) => li.li_ship_qtys != 0).length !== 0) {
        await shipService
          .postShip({
            so_id: this.getOrder[0].so_id,
            ship_id: this.getOrder[0].so_id,
            lis_items: mutatedLis,
            ship_seq_no: this.getOrder[0].ship_seq,
            ship_to_address_items: this.getOrder[0].ship_address_items,
            ship_name: this.getOrder[0].ship_name,
            ship_city: this.getOrder[0].ship_city,
            ship_state: this.getOrder[0].ship_state,
            ship_zip: this.getOrder[0].ship_zip,
            ship_country: this.getOrder[0].ship_country,
            ship_attn: this.getOrder[0].attn,
            ship_comm: this.getOrder[0].comm,
            ship_phone: this.getOrder[0].ship_phone,
          })
          .then((response: any) => {
            if (response.status === "success") {
              shipResponse = response;
              this.addNotification({
                message: `Order #${this.getOrder[0].so_id} has been partially shipped`,
                type: NotificationType.SUCCESS,
              });
            }
          })
          .finally(() => {
            shipped = true;
          });
      } else {
        shipped = true;
      }
      this.createPos();

      //get invoice and route to payment
      //search invoice

      await Utils.until((_: any) => shipped);
      await this.promptUser(shipResponse);
      if (shipResponse.status === "success") {
        Utils.selectOrderInvoice(
          shipResponse,
          () => {
            this.loadingOrderButton = false;
          },
          () => {
            this.handleShowKeepCustomerDialog();
          },
        ).then(async () => {
          this.setStoredCartItems({
            items: this.getStoredCartItems.summaryOrder.shift() || [],
            type: "summaryOrder",
          });
          if (this.getStoredCartItems.orderInvoice?.length > 0) {
            this.setCurrentActivity("INVOICES");
            this.replaceOrder(this.getStoredCartItems.orderInvoice || []);
            // route to payment
            //if it's a payout, add a paynote
            await this.applyPayoutLogic();
          } else if (this.getStoredCartItems.summaryOrder?.length > 0) {
            this.replaceOrder(this.getStoredCartItems.summaryOrder || []);
            this.$router.push("/pos/orders");
          } else {
            this.$router.push("/pos/customers");
          }
        });
      } else {
        this.setStoredCartItems({
          items: this.getStoredCartItems.summaryOrder.shift() || [],
          type: "summaryOrder",
        });
        if (this.getStoredCartItems.summaryOrder?.length > 0) {
          this.replaceOrder(this.getStoredCartItems.summaryOrder || []);
          this.$router.push("/pos/orders");
        } else {
          this.$router.push("/pos/customers");
        }
      }
    },
    updateInvoiceForRefund(event: any) {
      this.getOrder[0].li_items = event.li_items;
      this.selectedInvoiceLineItems = this.getOrder[0].li_items; // TODO: For now we only support refund on one invoice
    },
    onProcessedRefund() {
      this.clearOrder();
    },
    async createPos() {
      let newPos = [] as any;
      let updatePos = {} as any;
      updatePos.oldRecords = [] as any;
      updatePos.newRecords = [] as any;
      updatePos.filename = "PO";
      const meta = JSON.stringify({
        ignore_warning: "Y",
        process_type: "SAVE",
        api_version: 2,
      });
      updatePos.meta = meta;
      let pos = [] as any;
      const so_id = this.getOrder[0].so_id;
      this.getOrder[0].lis_items?.forEach((li: any) => {
        if (
          (li.po_id != undefined && li.po_status !== "C") ||
          (li.vendor && li.po_id == undefined)
        ) {
          pos.push({
            po_id: li.po_id,
            po_vendor: li.vendor,
            li_no_items: {
              part_no: li.li_parts,
              desc: li.wrap_desc,
              wrap_desc: li.wrap_desc,
              so_id: so_id,
              so_li: li.lis,
              sched_qty: li.li_order_qtys,
            },
          });
        }
      });
      //group same vendors together
      let groupedPos = pos.reduce((r: any, a: any) => {
        r[a.po_vendor.vendor_id] = [...(r[a.po_vendor.vendor_id] || []), a];
        return r;
      }, {});

      for (let vendor in groupedPos) {
        let posArray = groupedPos[vendor];
        let hasPoId = posArray.some((item: any) => item.po_id);
        if (hasPoId && posArray.length > 1) {
          let itemWithPoId = posArray.find((item: any) => item.po_id);
          if (itemWithPoId?.po_id) {
            poService.getPOById(itemWithPoId.po_id).then((poResponse: any) => {
              let po = cloneDeep(poResponse);
              let itemsWithoutPoId = posArray.filter(
                (item: any) => !item.po_id,
              );
              itemsWithoutPoId.forEach((item: any) => {
                po.li_no_items = po.li_no_items.map((item: any, index: any) => {
                  return {
                    ...item,
                    li_no: index + 1,
                  };
                });
              });
              updatePos.oldRecords.push(poResponse);
              updatePos.newRecords.push(po);
            });
          }
        } else if (!hasPoId) {
          //add lines into one object
          let po = posArray.reduce(
            (acc: any, curr: any, index: number) => {
              let itemWithIndex = {
                ...curr.li_no_items,
                li_no: index + 1,
              };
              acc.li_no_items.push(itemWithIndex);
              acc.vendor = curr.po_vendor.vendor_id;
              return acc;
            },
            { li_no_items: [] },
          );
          //add to list of POs that need to be created
          newPos.push(po);
        }
      }
      //create new POs
      if (newPos.length > 0) {
        await batchService
          .createRecords({ newRecord: newPos, filename: "PO", meta: meta })
          .then((response: any) => {
            if (response.status === "success") {
              this.addNotification({
                message: `PO(s) have been created for Order #${so_id}`,
                type: NotificationType.SUCCESS,
              });
            }
          });
      }
      //update existing POs
      if (updatePos.oldRecords.length > 0) {
        await batchService.updateRecords(updatePos).then((response: any) => {
          if (response.status === "success") {
            this.addNotification({
              message: `PO(s) have been updated for Order #${so_id}`,
              type: NotificationType.SUCCESS,
            });
          }
        });
      }
    },
    reverseOAAfterFailure(amount: any) {
      if (Math.abs(+amount) > 0) {
        this.addOaLine(amount * -1);
      }
    },
    getNewInvAmtMinMaxValues(inv: any) {
      if (parseFloat(inv.balance) >= 0) {
        this.newInvAmtMin = 0;
        this.newInvAmtMax = parseFloat(inv.original_balance || inv.balance);
      } else {
        this.newInvAmtMin = parseFloat(inv.original_balance || inv.balance);
        this.newInvAmtMax = 0;
      }
    },
    updateArAmount(part: any) {
      this.newInvAmt = part.balance;
      this.getNewInvAmtMinMaxValues(part);
      this.showAmountModal = true;
      this.updatingAr = part;
    },
    updateTender(tender: any, index: number) {
      if (this.isPayout) {
        return;
      }
      this.showTenderAmountModal = true;
      this.updatingTndr = tender;
      this.tndrIndex = index;
    },
    clearMenuItems() {
      this.getSalesOrder.pos_order_code = "";
    },
    updateTenderAmount() {
      let newTender = cloneDeep(this.getTenders[this.tndrIndex]);
      newTender.check_amount = (
        Math.round(parseFloat(this.newTndrAmt) * 100) / 100
      ).toFixed(2);
      this.updateTenderAmt({ tenderIndex: this.tndrIndex, tender: newTender });
      this.showTenderAmountModal = false;
    },
    updateAmount() {
      if (!this.v$.newInvAmt.$invalid) {
        let index = this.getOrder.findIndex(
          (item: any) => item.ar_id === this.updatingAr.ar_id,
        );
        let newOrder = cloneDeep(this.getOrder[index]);
        newOrder.original_balance =
          newOrder.original_balance || newOrder.balance;
        newOrder.balance = (
          Math.round(parseFloat(this.newInvAmt) * 100) / 100
        ).toFixed(2);
        this.updateInvAmt({ arIndex: index, ar: newOrder });
        this.clearTenders();
        this.oa = true;
        this.showAmountModal = false;
      }
    },
    addPayNote() {
      if (this.submitText === "Payout" || this.getOrder[0].type === "PO") {
        let po_items = [] as any;
        this.getOrder.forEach((ar: any) => {
          po_items.push({
            ar_id: ar.ar_id,
            amount: parseFloat(ar.balance).toFixed(2).toString(),
            type: "PO",
            balance: parseFloat(ar.balance).toFixed(2).toString(),
            pay_note: this.payNote,
          });
        });
        this.replaceOrder(po_items);
        this.showNoteModal = false;
        this.payNote = "";
        this.$router.push("/pos/payment");
      }
    },
    submitTrans() {
      actionService
        .postAction(
          this.transaction.code,
          this.modalValues,
          this.getRegister.reg_id,
        )
        .then((response: any) => {
          if (response.status === "success") {
            let resp = response;
          }
        });
      (this.modalValues = {} as { [k: string]: any }),
        (this.transaction = {} as any);
      this.displayDataModal = false;
    },
    changeTab(tab: string) {
      if (
        !(this.getOrder[0]?.ar_id === "OA" || this.getOrder[0]?.ar_id === "OI")
      ) {
        this.saveCurrentTabCart();
      }
      this.getSelectedTabCart(tab);
    },
    getSelectedTabCart(tab: string) {
      switch (tab) {
        case "/pos/parts":
          this.setCurrentActivity("PARTS");
          break;
        case "/pos/shipping":
          this.setCurrentActivity("PARTS");
          break;
        case "/pos/orders":
          this.replaceOrder(this.getStoredCartItems.summaryOrder || []);
          this.setCurrentActivity("ORDERS");
          break;
        case "/pos/invoices":
          this.replaceOrder(this.getStoredCartItems.orderInvoice || []);
          this.setCurrentActivity("INVOICES");
          break;
        case "/pos/confirmation":
          break;
        default:
          this.replaceOrder([]);
          break;
      }
    },
    saveCurrentTabCart() {
      switch (this.$route.fullPath) {
        case "/pos/parts":
          this.setCurrentActivity("PARTS");
          break;
        case "/pos/orders":
          this.setStoredCartItems({
            items: cloneDeep(this.getOrder || []),
            type: "summaryOrder",
          });
          this.setCurrentActivity("ORDERS");
          break;
        case "/pos/invoices":
          this.setStoredCartItems({
            items: cloneDeep(this.getOrder || []),
            type: "orderInvoice",
          });
          this.setCurrentActivity("INVOICES");
          break;
        default:
          break;
      }
    },
    confirmCfee() {
      return new Promise((resolve) => {
        this.$confirm.require({
          message:
            "Please verify customer is aware they may incur a 2.5% credit card fee \n Do not proceed without verbal commitment.",
          header: "Confirmation for Order #" + this.currentSo,
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.currentSo = "";
            this.cfeeResp = "accept";
            resolve(true);
          },
          reject: () => {
            this.addNotification({
              message: `Credit Card Fee has not been accepted. Order # ${this.currentSo} has not been finalized`,
              type: NotificationType.ERROR,
            });
            this.currentSo = "";
            resolve(false);
          },
        });
      });
    },
    open() {
      //call action with amount
      //if success
      let newReg = cloneDeep(this.getRegister);
      newReg.amount = this.openAmt;
      newReg.status = "Open";
      this.updateReg({
        reg_id: this.getRegister.reg_id,
        newReg: newReg,
        oldReg: this.getRegister,
        user: this.getUser.user_id,
      });
    },
    close() {
      let newReg = cloneDeep(this.getRegister);
      newReg.status = "Closed";
      this.updateReg({
        reg_id: this.getRegister.reg_id,
        newReg: newReg,
        oldReg: this.getRegister,
        user: this.getUser.user_id,
      });
      this.clearTenders();
      this.clearOrder();
      this.$router.push("/pos/customers");
    },
    clearCustFull(isLeavingPOS: boolean): void {
      this.clearCust();
      this.clearOrder();
      this.clearTenders();
      this.clearTaxAmount();
      if (isLeavingPOS) {
        this.$router.push("/pos/customers");
      }
    },
    clearRegFull() {
      this.clearReg();
      this.clearTenders();
      this.clearOrder();
      this.$router.push("/pos/customers");
    },
    setRegister(event: any) {
      this.setReg(event.target.value).then(() =>
        this.$router.push("/pos/customers"),
      );
    },
    changeToolBarTitle(sideMenuLabel: any) {
      this.changeTitle(sideMenuLabel.label);
    },
    onFilterRegOptionsList() {
      this.filteredRegOptions = this.getRegisterOptions?.filter((item: any) =>
        item.reg_id.toLowerCase().includes(this.reg.toLowerCase()),
      );
    },
    getActiveTab() {
      this.computedItems.forEach((item: any, index: any) => {
        if (item.to === this.$route.fullPath) {
          this.active = index;
        }
      });
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    formatDiscountPrice(amount: number) {
      const discPercent = parseFloat(this.getCustomer.disc) / 100;
      amount = amount - amount * discPercent;
      return Utils.formatPrice(amount);
    },
    formatDate(date: any) {
      return Utils.formatDate(date);
    },
    setCoCode() {
      if (this.getSelectedCoCode !== this.getDefaultCodeItem) {
        this.getSalesOrder.pos_order_code = "";
        this.setSelectedCoCode(this.getDefaultCodeItem);
      }
    },
    async getRegMode() {
      // update new register hardware logic
      // fetch the register details from local desktop service
      // check local service for register
      if (this.allowMixedMode) {
        if (this.getUser.bypass_reg_mode === "Y") {
          this.checkingPosMode = false;
          return;
        }
        this.checkingPosMode = false;
        await this.checkLocalService();
        if (
          !this.forceUserMode &&
          (this.getRegister === null || this.getRegister === "")
        ) {
          await this.loadRegisters();
        }
      } else {
        await this.loadRegisters();
        if (
          this.isRegisterMode &&
          (this.getRegister === null || this.getRegister === "")
        ) {
          this.checkingPosMode = false;
          await this.checkLocalService();
        } else {
          this.checkingPosMode = false;
        }
      }
    },
    async loadRegisters() {
      let registerStore = this.getUser.attributes.find(
        (attribute: any) => attribute.field_name === "STORE",
      )?.value;
      this.isLoadingRegOptions = true;
      await regService
        .fetchStoreRegister(registerStore)
        .then((response: any) => {
          this.setRegisterOptions(response.reg_items);
          this.onFilterRegOptionsList();
        })
        .finally(() => {
          this.isLoadingRegOptions = false;
        });
    },
    onRegisterClick(selectedRegister: string) {
      // build object for updateRegister
      const updateRegObj = {
        SessionID: null,
        Fingerprint: this.registerFingerprint,
        Name: this.registerHostname,
        RegisterID: selectedRegister,
      };

      this.updateRegisterHardware(updateRegObj).then((response) => {
        if (!response.error) {
          if (parseFloat(this.session.user.security_level) < 80) {
            this.adminAccess = false;
            this.$router.push("/pos/customers");
          }
        }
      });
    },

    async handleShowKeepCustomerDialog() {
      if (
        this.getSalesOrder.lis_items ||
        this.getStoredCartItems.summaryOrder.length ||
        this.getStoredCartItems.orderInvoice.length
      ) {
        const response = await this.onLeavingPOS(false);
        if (response) {
          this.clearCustFull(true);
        } else {
          this.setSalesOrder({
            order: Utils.initNewOrderFromCustomer(
              this.getCustomer,
              this.getSelectedCoCode,
              this.getSalesTaxCodes,
            ),
            date_created: new Date(),
            racks: [],
          });
        }
      } else {
        this.clearCustFull(false);
      }
    },
    getPartsTotal(order: SalesOrder): string {
      let sum = 0;

      order.lis_items?.forEach((part: LisItems) => {
        const quantity = parseFloat(part.li_order_qtys) || 0;
        const price = parseFloat(part.li_prices) || 0;
        const discount = parseFloat(part.li_discs || "0") / 100;
        sum += (price - price * discount) * quantity || 0;
      });
      return sum.toFixed(2);
    },
    getTotal(order: any): string {
      let sum = 0;
      let disc = 0.0;
      if (this.getCustomer?.disc) {
        disc = parseFloat(this.getCustomer.disc) / 100 || 0.0;
      }

      order.forEach((part: any) => {
        const quantity = part.quantity > 1 ? part.quantity : 1;
        const price = parseFloat(part.price) || 0;
        sum += (price - price * disc) * quantity || 0;
        sum += parseFloat(part.order_amount) || 0;
        sum += parseFloat(part.balance) || 0;
      });
      return sum.toFixed(2);
    },
    removeEditPart(event: any, indexToRemove: number) {
      this.selectedLineItemIndex = -1;
    },
    onLeavingPOS(isLeavingPOS = true) {
      return new Promise((resolve) => {
        // We get the message with the areas where there are unfinished transactions
        let customMessage =
          '<span class="inline-block mb-2">You have the following transaction(s) in progress. Select a transaction to resume it, or click the "Discard" button to discard your changes.</span><br />';
        this.continueTransactionLabelItems = [];
        if (this.getSalesOrder.lis_items?.length) {
          const formatedTotal = parseFloat(
            this.getPartsTotal(this.getSalesOrder),
          );
          const formatedTotalWithTax = this.formatPrice(
            formatedTotal + (parseFloat(this.getTaxAmount) || 0),
          );
          const totalItems = this.getSalesOrder.lis_items.length;
          if (this.getSalesOrder.so_id) {
            this.continueTransactionLabelItems.push({
              label: `Editing an existing order #${this.getSalesOrder.so_id} with `,
              message: `${totalItems} item(s) totaling ${formatedTotalWithTax}`,
              to: "/pos/parts",
              draft: !this.hideDraftedOrders,
            });
          } else {
            this.continueTransactionLabelItems.push({
              label: "A new order with ",
              message: `${totalItems} item(s) totaling ${formatedTotalWithTax}`,
              to: "/pos/parts",
              draft: !this.hideDraftedOrders,
            });
          }
        }
        if (this.getStoredCartItems.summaryOrder.length) {
          const formatedPrice = this.formatPrice(
            parseFloat(this.getTotal(this.getStoredCartItems.summaryOrder)),
          );
          const totalItems = this.getStoredCartItems.summaryOrder.length;
          this.continueTransactionLabelItems.push({
            label: "Finalization of ",
            message: `${totalItems} existing orders(s) totaling ${formatedPrice}`,
            to: "/pos/orders",
          });
        }
        if (this.getStoredCartItems.orderInvoice.length) {
          const formatedPrice = this.formatPrice(
            parseFloat(this.getTotal(this.getStoredCartItems.orderInvoice)),
          );
          const totalItems = this.getStoredCartItems.orderInvoice.length;
          this.continueTransactionLabelItems.push({
            label: "Payment of ",
            message: `${totalItems} open invoice(s) totaling ${formatedPrice}`,
            to: "/pos/invoices",
          });
        }

        if (this.continueTransactionLabelItems.length === 0) {
          resolve(true);
          return;
        }

        // We get the title deppending on the user leaving the POS or not
        const customHeader = "Transactions in Progress";

        this.$confirm.require({
          group: "continueTransaction",
          message: customMessage,
          header: customHeader,
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Discard",
          rejectLabel: "Keep Changes",
          accept: () => {
            this.clearCustFull(isLeavingPOS);
            this.continueTransactionLabelItems = [];
            this.clearStoredCartItems();
            resolve(true);
            setTimeout(() => {
              this.$confirm.close();
            }, 100);
          },
          reject: () => {
            resolve(false);
            this.$confirm.close();
          },
          onHide: () => {
            resolve(false);
            this.$confirm.close();
          },
        });
      });
    },
    onEntryUserAuthenticate(event: any) {
      this.setEntryUser(event.user_id);
    },
  },
  watch: {
    async isOnline(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        const response = await this.fetchUnsavedOrders();
        if (response && response.length > 0) {
          this.unsavedOrders = response;
        }
      }
    },
    getCustomer: function (customer, oldCustomer) {
      // Check if there is a change to customer in shipping (add address). Ignore route to parts.
      // Critical for review
      if (customer && oldCustomer && customer.cust_id === oldCustomer.cust_id) {
        if (this.$route.fullPath === "/pos/shipping") {
          return;
        }
      }

      if (this.$route.fullPath === "/pos/pof") {
        return;
      }
      if (
        this.$route.fullPath !== "/pos/shipping" &&
        this.$route.fullPath !== "/pos/order-created" &&
        (customer === null || customer === "")
      ) {
        this.$router.push("/pos/customers");
      }
      if (customer !== oldCustomer) {
        this.clearMenuItems();
      }

      if (!customer) {
        return;
      }

      if (this.getStoredCartItems.orderInvoice.length > 0) {
        this.setCurrentActivity("INVOICES");
        this.$router.push("/pos/invoices");
        this.replaceOrder(this.getStoredCartItems.orderInvoice);
      } else if (this.getStoredCartItems.summaryOrder.length > 0) {
        this.setCurrentActivity("ORDERS");
        this.$router.push("/pos/orders");
        this.replaceOrder(this.getStoredCartItems.summaryOrder);
      } else {
        this.setCurrentActivity("PARTS");

        if (this.orderInformationPrecedesCreateOrder) {
          this.$router.push("/pos/shipping");
        } else {
          this.$router.push("/pos/parts");
        }
      }
    },
    getSelectedCoCode: function () {
      this.getSOTypes();
    },
    isCash: {
      handler(newValue) {
        if (!newValue) {
          this.oa = true;
        }
        if (
          newValue &&
          this.getRegister?.default_overage_change_for_cash === "Y"
        ) {
          this.oa = false;
        }
      },
      deep: true,
    },
    getRegister: function () {
      if (
        (this.getRegister === null || this.getRegister === "") &&
        !this.isRegisterMode
      ) {
        this.loadRegisters();
        this.onFilterRegOptionsList();
      }
    },
    $route: function (to, from, next) {
      if (
        to.fullPath === "/pos/parts" ||
        this.$route.fullPath === "/pos/parts"
      ) {
        if (this.hidePartsTab) {
          this.$router.push("/pos/orders");
        }

        if (this.getCustomer?.cust_id) {
          this.getSelectedTabCart(this.$route.fullPath);
        } else if (this.getSalesOrder.sold_to) {
          customerService
            .getCustomer(
              this.getSalesOrder.sold_to,
              this.getClient,
              "contact_email contact_name",
            )
            .then((response: any) => {
              this.setCust({
                cust_items: [response],
              });
              this.setCust(response);
              this.getSelectedTabCart(this.$route.fullPath);
            });
        } else {
          this.$router.push("/pos/customers");
        }
        this.active = 0;
      }
      if (
        to.fullPath === "/pos/orders" ||
        this.$route.fullPath === "/pos/orders"
      ) {
        this.getSelectedTabCart(this.$route.fullPath);
        this.active = 1;
        this.clearTenders;
        this.clearMenuItems();
      }
      if (
        to.fullPath === "/pos/invoices" ||
        this.$route.fullPath === "/pos/invoices"
      ) {
        this.getSelectedTabCart(this.$route.fullPath);
        this.active = 2;
        this.clearTenders;
        this.clearMenuItems();
      }
      if (from.fullPath === "/pos/payment") {
        this.getSelectedTabCart(this.$route.fullPath);
        this.clearTenders;
        this.clearMenuItems();
      }
      if (to.fullPath === "/pos/shipping") {
        this.active = 0;
        this.updateOrderInformation();
      }
      if (this.hidePartsTab) {
        this.active--;
      }
    },
    getOrder: {
      handler: function () {
        if (this.getOrder.length > 0) {
          this.updateOrderInformation();
          if (
            this.$route.fullPath === "/pos/parts" ||
            this.$route.fullPath === "/pos/shipping"
          ) {
            this.getTaxes();
          }
        }
      },
      deep: true,
    },
    getSalesOrder: {
      handler: function () {
        this.saveSalesOrder();
        if (this.getSalesOrder) {
          this.getTaxes();
        }
      },
      deep: true,
    },
  },
  provide() {
    return {
      companyCode: computed(() => this.getSelectedCoCode),
      customerId: computed(() => this.getCustomer?.cust_id),
      invalidSubmit: computed(() => this.invalidSubmit),
    };
  },
});
